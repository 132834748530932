import { GET_ALL_SUBSCRIPTION_RESPONSE } from "../constants/ActionTypes";

const initialState = {
  list: []
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION_RESPONSE:
      return { ...state, list: action.data };
    default:
      return state;
  }
};

export default planReducer;