export const getTransaction = {
  query: `query transactionPagination(
      $page: Int
      $perPage: Int = 20
      $filter: FilterFindManyTransactionInput
      $sort: SortFindManyTransactionInput
    ) {
      transactionPagination(
        page: $page
        perPage: $perPage
        filter: $filter
        sort: $sort
      ) {
        count
        pageInfo {
          pageCount
          perPage
          currentPage
        }
        items {
          _id
          transaction_id
          User {
            fullname
            email
            phone
            address
            zipcode
          }
          ref_id
          amount
          gateway
          pending_pay_amount
          process_status
          create_date
          Subscription {
            _id
            Plan {
              _id
              User {
                fullname
                phone
                address
                logo
                brand_name
              }
            }
          }
        }
      }
    }
    `
}

export const CreateOneTransactionInput = {
  query: `
  mutation transactionCreateOne(
    $amount: Float!
    $user_id: MongoID!
    $currency: String
  ) {
    transactionCreateOne(
      amount: $amount
      user_id: $user_id
      currency: $currency
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`};

export const transactionUpdateOne = {
  query: `
  mutation transactionUpdateOne(
    $amount: Float!
    $user_id: MongoID!
    $ref_id: String!
    $payment_id: String!
    $signature: String!
    $emp_id: String
  ) {
    transactionUpdateOne(
      amount: $amount
      user_id: $user_id
      ref_id: $ref_id
      payment_id: $payment_id
      signature: $signature
      emp_id: $emp_id
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`};

export const transactionRequestMoney = {
  query: `mutation transactionRequestMoney(
    $amount: Float!
    $user_id: MongoID!
    $currency: String
    $isOffline: Boolean
  ) {
    transactionRequestMoney(
      amount: $amount
      user_id: $user_id
      currency: $currency
      isOffline: $isOffline
    ) {
      transaction_id
      amount
    }
  }`
}

export const transactionUpdateRequestMoney = {
  query: `mutation transactionUpdateRequestMoney(
    $_id: MongoID!
    $status: String!
    $ref_id: String!
  ) {
    transactionUpdateRequestMoney(
      _id: $_id
      status: $status
      ref_id: $ref_id
    ) {
      _id
      transaction_id
      process_status
    }
  }`
}