import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import {
  getSingleChannel,
  getUsers,
  updateChannelDetailsById,
  createChannel,
} from "actions";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

const initState = {
  operator_id: "",
  name: "",
  price: "",
  status: "1",
};

class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = { ...initState, operators: [] }
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      let payload = {
        id: this.props.match.params.id,
      };
      this.props.getSingleChannel(payload);
    }
    // this.props.getUsers({ filter: { isUser: false } });
  };
  componentDidUpdate(previousProps) {
    if (
      this.props.channel &&
      this.props.channel !== previousProps.channel &&
      this.props.channel.channelById
    ) {
      let data = this.props.channel.channelById;
      this.setState({
        operator_id: data.operator_id,
        price: data.price,
        status: data.status,
        name: data.name,
      });
    }
    if (
      this.props.listUser !== previousProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      let items = [];
      this.props.listUser.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      if (
        this.props.user.login &&
        this.props.user.login._id &&
        this.props.user.login.role !== "superadmin"
      ) {
        this.setState({ operator_id: this.props.user.login._id });
      } else {
        this.setState({ operators: items });
      }
    }
    if (this.props.createChannelRes !== previousProps.createChannelRes) {
      const operator_id = this.state.operator_id === this.props.user.login._id ? this.props.user.login._id : false
      this.setState(initState)
      operator_id && this.setState({ operator_id })
      this.validator.hideMessages();
    }
  }
  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  update = async () => {
    if (this.validator.allValid()) {
      let payload = {
        record: {
          name: this.state.name,
          price: this.state.price,
          operator_id: this.state.operator_id,
          status: this.state.status,
        },
      };
      if (this.props.match.params.id)
        payload.record._id = this.props.match.params.id;

      if (this.props.match.params.id) {
        this.props.updateChannelDetailsById(payload);
      } else {
        this.props.createChannel(payload);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{`${this.props.match.params.id ? "Update Paid channels / சேனலைப் புதுப்பி" : "Create channels / சேனலைச் சேர்க்க"
                      }`}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column mt-5">
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Name / பெயர்"
                              type="text"
                              value={this.state.name}
                              onChange={(e) => {
                                this.chage(e, "name");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Name",
                            this.state.name,
                            "string|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Price / விலை"
                              type="number"
                              value={this.state.price}
                              onChange={(e) => {
                                this.chage(e, "price");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Price",
                            this.state.price,
                            "numeric|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              className={`react-select info ${this.state.operator_id === this.props.user.login._id ? "d-none" : ''}`}
                              classNamePrefix="react-select"
                              name="operator_id"
                              value={this.state.operator_id && this.state.operators.find(
                                (data) =>
                                  data.value === this.state.operator_id
                              )}
                              onChange={(value) =>
                                this.setState({ operator_id: value.value })
                              }
                              options={this.state.operators}
                              placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Operator id",
                            this.state.operator_id,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.update}>
                      {this.props.match.params.id ? 'Update / புதுப்பி' : 'create / உருவாக்கு'}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    channel: state.channel.singleChannel,
    createChannelRes: state.channel.createChannel,
    listUser: state.user.operators,
    user: state.user.login,
  };
};

export default connect(mapStateToProps, {
  getSingleChannel,
  getUsers,
  createChannel,
  updateChannelDetailsById,
})(Channel);
