import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "util/cookies";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        getCookie("__user") && getCookie("__user") !== "" ? (
          <Component {...props} />
        ) : (
          <Redirect from="/" to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
