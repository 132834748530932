export const getSubscription = {
  query: `query subscriptionPagination(
        $page: Int
        $perPage: Int = 20
        $filter: FilterFindManySubscriptionInput
        $sort: SortFindManySubscriptionInput
      ) {
        subscriptionPagination(
          page: $page
          perPage: $perPage
          filter: $filter
          sort: $sort
        ) {
          count
          items {
            _id
            plan_id
            user_id
            next_pay_amount
            lat_pay_amount
            pending_pay_amount
            Plan {
              amount
              status
            }
            User {
              fullname
              phone
              email
              address
              zipcode
            }
          }
          pageInfo {
            pageCount
            perPage
            currentPage
          }
        }
      }`,
};

export const createSubscription = {
  query: `mutation subscriptionCreate($record: CreateOneSubscriptionInput!) {
    subscriptionCreate(record: $record) {
      record {
        user_id
        plan_id
        next_pay_amount
        lat_pay_amount
        pending_pay_amount
        status
      }
    }
  }`,
};

export const getSubscriptionById = {
  query: `query subscriptionById($id: MongoID!) {
    subscriptionById(_id: $id) {
      subscription_id
      _id
      plan_id
      user_id
      next_pay_amount
      lat_pay_amount
      pending_pay_amount
      Plan {
        amount
        status
      }
      User {
        fullname
        phone
        email
        address
        zipcode
      }
    }
  }`,
};
