import { GET_ALL_TRANSACTION_RESPONSE, CREATE_TRANSACTION_RESPONSE, REQUEST_FUND_RESPONSE } from "../constants/ActionTypes";

const initalState = {
  list: [],
  update: '',
  fundRequest: {}
};

const transactionReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTION_RESPONSE:
      return { ...state, list: action.data };
    case CREATE_TRANSACTION_RESPONSE:
      return { ...state, update: action.data };
    case REQUEST_FUND_RESPONSE:
      return { ...state, fundRequest: action.data };
    default:
      return state;
  }
};

export default transactionReducer;
