import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { getUserById, updateUserById, addUser } from "actions";
import { connect } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      fullName: "",
      countryCode: "",
      phone: "",
      zipCode: "",
      deviceId: "",
      fbId: "",
      twiterId: "",
      emailId: "",
      currency: {},
      lastverified: "",
      location: "",
      address: "",
      password: "",
      brand_name: "",
      logo: "",
      role: "employee",
      operator_id: this.props.user.login && this.props.user.login._id,
      passWrd: true,
    };
  }

  chageDateFormat = (date) => {
    let newDateFormat = moment(date, "YYYY-MM-DD HH:mm:ss").format(
      "YYYY-MM-DD"
    );
    return newDateFormat;
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      let payload = {
        id: this.props.match.params.id,
      };
      this.props.getUserById(payload);
    }
  };

  componentDidUpdate(previousProps) {
    if (this.props.user.user !== previousProps.user.user) {
      let data = this.props.user.user.userById;
      this.setState({
        fullName: data.fullname,
        countryCode: data.country_code,
        phone: data.phone,
        zipCode: data.zipcode,
        deviceId: data.device_id,
        fbId: data.fb_id,
        twiterId: data.twitter_id,
        emailId: data.email,
        password: data.password,
        Currency: data.currency,
        lastverified: data.last_verified,
        onlineStatus: data.isOnline,
        address: data.address,
        brand_name: data.brand_name,
        logo: data.logo,
        role: "employee",
        operator_id: this.props.user.login && this.props.user.login._id
      });
    }
    if (this.props.user.addUser !== previousProps.user.addUser) {
      this.props.history.push('/admin/list-operator/')
    }
  }

  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }

  update = async () => {
    if (this.validator.allValid()) {
      let payload = {
        record: {
          fullname: this.state.fullName,
          country_code: this.state.countryCode,
          phone: this.state.phone,
          zipcode: this.state.zipCode,
          device_id: this.state.deviceId,
          fb_id: this.state.fbId,
          twitter_id: this.state.twiterId,
          email: this.state.emailId,
          address: this.state.address,
          brand_name: this.state.brand_name,
          logo: this.state.logo,
          isUser: false,
          role: "employee",
          operator_id: this.props.user.login && this.props.user.login.login && this.props.user.login.login._id
        },
      };
      if (this.props.match.params.id) {
        payload.filter = {
          _id: this.props.match.params.id,
        };
        this.props.updateUserById(JSON.stringify(payload));
      } else {
        payload.record.password = this.state.password
        this.props.addUser(JSON.stringify(payload));
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleImageCgange = (value) => this.setState({ logo: value });

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{`${this.props.match.params.id ? "Update" : "Create"
                      } Employee`}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column mt-5">
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Full Name"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "fullName");
                              }}
                              value={this.state.fullName}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Full Name",
                            this.state.fullName,
                            "alpha_space|min:3|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Company Name"
                              type="text"
                              value={this.state.brand_name}
                              onChange={(e) => {
                                this.chage(e, "brand_name");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Company Name",
                            this.state.brand_name,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Country Code"
                              value={this.state.countryCode}
                              onChange={(e) => {
                                this.chage(e, "countryCode");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Country Code",
                            this.state.countryCode,
                            "min:2|max:2|numeric|min:0,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Phone"
                              type="number"
                              value={this.state.phone}
                              onChange={(e) => {
                                this.chage(e, "phone");
                              }}
                              maxLength={10}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Phone",
                            this.state.phone,
                            "min:10|max:10|numeric|min:0,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Email"
                              value={this.state.emailId}
                              onChange={(e) => {
                                this.chage(e, "emailId");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Email",
                            this.state.emailId,
                            "required|email",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Zip Code"
                              value={this.state.zipCode}
                              onChange={(e) => {
                                this.chage(e, "zipCode");
                              }}
                              maxLength={6}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Zip Code",
                            this.state.zipCode,
                            "min:6|max:6|numeric|min:0,num",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Address"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "address");
                              }}
                              value={this.state.address}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Address",
                            this.state.address,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <div className="pwdWrpr w-100">
                            <FormGroup>
                              <Input
                                placeholder="Password"
                                type={this.state.passWrd ? 'password' : 'text'}
                                onChange={(e) => {
                                  this.chage(e, "password");
                                }}
                                value={this.state.password}
                                disabled={
                                  this.props.match.params.id ? true : false
                                }
                              />
                              {this.state.passWrd ?
                                <i class="pwdIcon fa fa-eye" aria-hidden="true" onClick={() => this.setState({ passWrd: false })}></i> :
                                <i class="pwdIcon fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ passWrd: true })}></i>
                              }
                            </FormGroup>
                          </div>
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "min:6|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.update}>
                      {this.props.match.params.id ? 'Update / புதுப்பி' : 'create / உருவாக்கு'}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  getUserById,
  updateUserById,
  addUser
})(EditUser);
