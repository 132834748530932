// User
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE';
export const LIST_USER = 'LIST_USER';
export const LIST_USER_RESPONSE = 'LIST_USER_RESPONSE';
export const LIST_OPERATORS = 'LIST_OPERATORS';
export const LIST_OPERATORS_RESPONSE = 'LIST_OPERATORS_RESPONSE';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_RESPONSE = 'GET_USER_BY_ID_RESPONSE';
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';
export const UPDATE_USER_BY_ID_RESPONSE = 'UPDATE_USER_BY_ID_RESPONSE';

export const LOGO_UPLOAD = 'LOGO_UPLOAD';
export const LOGO_UPLOAD_RESPONSE = 'LOGO_UPLOAD_RESPONSE';

export const CREATE_PLAN = 'CREATE_PLAN';
export const CREATE_PLAN_RESPONSE = 'CREATE_PLAN_RESPONSE';
export const LIST_PLAN = 'LIST_PLAN';
export const LIST_PLAN_RESPONSE = 'LIST_PLAN_RESPONSE';
export const SINGLE_PLAN = 'SINGLE_PLAN';
export const SINGLE_PLAN_RESPONSE = 'SINGLE_PLAN_RESPONSE';
export const UPDATE_PLAN_DETAILS_BY_ID = 'UPDATE_VECHCILEDETAILS_BY_ID';
export const UPDATE_PLAN_RESPONSE = 'UPDATE_PLAN_RESPONSE';


export const GET_ALL_SUBSCRIPTION = 'GET_ALL_SUBSCRIPTION';
export const GET_ALL_SUBSCRIPTION_RESPONSE = 'GET_ALL_SUBSCRIPTION_RESPONSE';

export const GET_ALL_TRANSACTION = 'GET_ALL_TRANSACTION';
export const GET_ALL_TRANSACTION_RESPONSE = 'GET_ALL_TRANSACTION_RESPONSE';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_TRANSACTION_RESPONSE = 'CREATE_TRANSACTION_RESPONSE';


export const GET_BID_HISTORY = 'GET_BID_HISTORY';
export const GET_BID_HISTORY_RESPONSE = 'GET_BID_HISTORY_RESPONSE';


export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_RESPONSE = 'GET_NOTIFICATION_RESPONSE';
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST';
export const UPDATE_NOTIFICATION_RESPONSE = 'UPDATE_NOTIFICATION_RESPONSE';

export const ADD_USER = 'ADD_USER'
export const ADD_USER_RESPONSE = 'ADD_USER_RESPONSE'

export const GET_COUNT = 'GET_COUNT';
export const COUNT_RESPONSE = 'COUNT_RESPONSE';

export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const CREATE_CHANNEL_RESPONSE = 'CREATE_CHANNEL_RESPONSE';
export const LIST_CHANNEL = 'LIST_CHANNEL';
export const LIST_CHANNEL_RESPONSE = 'LIST_CHANNEL_RESPONSE';
export const SINGLE_CHANNEL = 'SINGLE_CHANNEL';
export const SINGLE_CHANNEL_RESPONSE = 'SINGLE_CHANNEL_RESPONSE';
export const UPDATE_CHANNEL_DETAILS_BY_ID = 'UPDATE_CHANNEL_DETAILS_BY_ID';
export const UPDATE_CHANNEL_RESPONSE = 'UPDATE_CHANNEL_RESPONSE';

export const REQUEST_FUND = 'REQUEST_FUND';
export const REQUEST_FUND_RESPONSE = 'REQUEST_FUND_RESPONSE';

export const UPDATE_TRANSACTION_STATUS_BY_ID = 'UPDATE_TRANSACTION_STATUS_BY_ID';
export const UPDATE_TRANSACTION_STATUS_BY_ID_RESPONSE = 'UPDATE_TRANSACTION_STATUS_BY_ID_RESPONSE';