import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { getUserById, updateUserById, addUser, getPlan, getChannel, getUsers } from "actions";
import { connect } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

const initState = {
  fullname: "",
  countryCode: "",
  phone: "",
  zipCode: "",
  deviceId: "",
  fbId: "",
  twiterId: "",
  emailId: "",
  currency: {},
  lastverified: "",
  location: "",
  address: "",
  password: "",
  brand_name: "",
  plan_id: "",
  operator_id: "",
  street_address: "",
  apartment_number: "",
  status: 1,
  extra_chennel: [],
  passWrd: true,
};


class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      ...initState, plans: [],
      channels: [],
      operators: [],
    }
  }
  chageDateFormat = (date) => {
    let newDateFormat = moment(date, "YYYY-MM-DD HH:mm:ss").format(
      "YYYY-MM-DD"
    );
    return newDateFormat;
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      let payload = {
        id: this.props.match.params.id,
      };
      this.props.getUserById(payload);
    }
    if (
      this.props.user.login &&
      this.props.user.login.login &&
      this.props.user.login.login._id &&
      this.props.user.login.login.role !== "superadmin"
    ) {
      this.props.getChannel({
        filter: { operator_id: this.props.user.login.login._id },
      });
      this.setState({ operator_id: this.props.user.login.login._id });
      this.props.getPlan({
        filter: { operator_id: this.props.user.login.login._id },
      });
    } else {
      // this.props.getUsers({ filter: { isUser: false }, limit: 1000 });
      this.props.getChannel({});
      // this.props.getPlan({});
    }
  };
  componentDidUpdate(previousProps) {
    if (
      this.props.user.user !== previousProps.user.user &&
      this.props.user.user &&
      this.props.user.user.userById
    ) {
      let data = this.props.user.user.userById;
      this.setState({
        fullname: data.fullname,
        countryCode: data.country_code,
        phone: data.phone,
        zipCode: data.zipcode,
        deviceId: data.device_id,
        fbId: data.fb_id,
        twiterId: data.twitter_id,
        emailId: data.email,
        password: data.password,
        Currency: data.currency,
        lastverified: data.last_verified,
        address: data.address.split(",")[2],
        street_address: data.address.split(",")[1],
        apartment_number: data.address.split(",")[0],
        plan_id: data.plan_id,
        operator_id: data.operator_id,
        extra_chennel: data.extra_chennel || []
      });
      if (data.operator_id) {
        this.props.getPlan({
          filter: { operator_id: data.operator_id },
        });
      }
    }
    if (
      this.props.listPlan !== previousProps.listPlan &&
      this.props.listPlan.planPagination &&
      this.props.listPlan.planPagination.items.length
    ) {
      let items = [];
      this.props.listPlan.planPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: `${item.name}-${item.amount}`,
        });
        return items;
      });
      // this.setState({
      //   operator_id: this.props.listPlan.planPagination.items[0].user_id,
      // });
      this.setState({ plans: items });
    }
    if (
      this.props.listChannel !== previousProps.listChannel
    ) {
      let items = [];
      this.props.listChannel.channelPagination.items.map((item) => {
        items.push({
          value: item.price,
          label: item.name,
        });
        return items;
      });
      this.setState({ channels: items });
    }
    if (
      this.props.listUser !== previousProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      let items = [];
      this.props.listUser.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.email + " | " + item.phone,
        });
        return items;
      });
      if (
        this.props.user.login.login &&
        this.props.user.login.login._id &&
        this.props.user.login.login.role !== "superadmin"
      ) {
        this.setState({ operator_id: this.props.user.login.login._id });
        if (this.props.user.login.login._id) {
          this.props.getPlan({
            filter: { operator_id: this.props.user.login.login._id },
          });
        }
      } else {
        this.setState({ operators: items });
      }
    }
    if (
      this.props.addUserProps !== previousProps.addUserProps
    ) {
      this.setState(initState)
      this.validator.hideMessages();
    }
  }
  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  update = async () => {
    if (this.validator.allValid()) {
      let totalPrice = 0
      this.state.extra_chennel.forEach(data => {
        totalPrice += parseFloat(data.value)
      })
      let payload = {
        record: {
          fullname: this.state.fullname,
          country_code: this.state.countryCode,
          phone: this.state.phone,
          zipcode: this.state.zipCode,
          device_id: this.state.deviceId,
          fb_id: this.state.fbId,
          twitter_id: this.state.twiterId,
          email: this.state.emailId,
          address:
            this.state.apartment_number +
            "," +
            this.state.street_address +
            "," +
            this.state.address,
          plan_id: this.state.plan_id,
          operator_id: this.state.operator_id,
          isUser: true,
          extra_chennel: this.state.extra_chennel,
          extra_chennel_total: totalPrice.toString(),
        },
      };
      if (this.props.match.params.id) {
        payload.filter = {
          _id: this.props.match.params.id,
        };
        this.props.updateUserById(JSON.stringify(payload));
      } else {
        payload.record.password = this.state.password;
        this.props.addUser(JSON.stringify(payload));
        // this.setState(initState)
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  addChennels = () => {
    this.state.extra_chennel.push({ chennel: "", value: "" })
    this.setState({
      extra_chennel: this.state.extra_chennel
    })
  }
  removeChennels = (index) => {
    this.state.extra_chennel.splice(index, 1);
    this.setState({
      extra_chennel: this.state.extra_chennel
    })
    this.validator.purgeFields()
  }
  operatorOnChangeHandler = (value) => {
    this.props.getPlan({
      filter: { operator_id: value.value },
    });
    this.setState({ operator_id: value.value })
  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{`${this.props.match.params.id ? "Update" : "Create"
                      } User`}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column mt-5">
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Full Name / முழு பெயர்"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "fullname");
                              }}
                              value={this.state.fullname}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Full Name",
                            this.state.fullname,
                            "alpha_space|min:5|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Box ID / பெட்டி ஐடி"
                              type="text"
                              value={this.state.deviceId}
                              onChange={(e) => {
                                this.chage(e, "deviceId");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Box ID",
                            this.state.deviceId,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Phone / கைபேசி எண்"
                              type="text"
                              value={this.state.phone}
                              onChange={(e) => {
                                this.chage(e, "phone");
                              }}
                              maxLength={10}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Phone",
                            this.state.phone,
                            "min:10|max:10|numeric|min:0,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              placeholder="Zip Code / அஞ்சல் குறியீடு"
                              value={this.state.zipCode}
                              onChange={(e) => {
                                this.chage(e, "zipCode");
                              }}
                              maxLength={6}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Zip Code",
                            this.state.zipCode,
                            "min:6|max:6|numeric|min:0,num",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Email / மின்னஞ்சல்"
                              value={this.state.emailId}
                              onChange={(e) => {
                                this.chage(e, "emailId");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Email",
                            this.state.emailId,
                            "required|email",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <div className="pwdWrpr w-100">
                            <FormGroup>
                              <Input
                                placeholder="Password / கடவுச்சொல்"
                                type={this.state.passWrd ? 'password' : 'text'}
                                onChange={(e) => {
                                  this.chage(e, "password");
                                }}
                                value={this.state.password}
                                disabled={
                                  this.props.match.params.id ? true : false
                                }
                              />
                              {this.state.passWrd ?
                                <i class="pwdIcon fa fa-eye" aria-hidden="true" onClick={() => this.setState({ passWrd: false })}></i> :
                                <i class="pwdIcon fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ passWrd: true })}></i>
                              }
                            </FormGroup>
                          </div>
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "min:6|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Apartment Number / அபார்ட்மெண்ட் எண்"
                              type="text"
                              value={this.state.apartment_number}
                              onChange={(e) => {
                                this.chage(e, "apartment_number");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Apartment Number",
                            this.state.apartment_number,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Address / முகவரி"
                              value={this.state.street_address}
                              onChange={(e) => {
                                this.chage(e, "street_address");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Street Address",
                            this.state.street_address,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="State / மாநிலம்"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "address");
                              }}
                              value={this.state.address}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "State",
                            this.state.address,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="currency"
                            defaultInputValue={"INR"}
                            onChange={(value) => {
                              this.setState({ currency: value });
                            }}
                            options={[
                              {
                                value: "",
                                label: "Select Currency",
                                isDisabled: true,
                              },
                              { value: "INR", label: "INR" },
                              { value: "USD", label: "USD" },
                            ]}
                            placeholder="Select Currency / நாணயத்தைத் தேர்ந்தெடுக்கவும்"
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        {this.props.user.login && this.props.user.login.login &&
                          this.props.user.login.login._id &&
                          this.props.user.login.login.role === "superadmin" &&
                          <Col sm="5">
                            <FormGroup>
                              <Select
                                className={`react-select info`}
                                classNamePrefix="react-select"
                                name="operator_id"
                                value={this.state.operator_id && this.state.operators.find(
                                  (data) =>
                                    data.value === this.state.operator_id
                                )}
                                onChange={this.operatorOnChangeHandler}
                                options={this.state.operators}
                                placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                              />
                            </FormGroup>
                            {this.validator.message(
                              "Operator id",
                              this.state.operator_id,
                              "required",
                              { className: "text-danger" }
                            )}
                          </Col>}
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              name="plan_id"
                              value={this.state.plan_id && this.state.plans.find(
                                (data) => data.value === this.state.plan_id
                              )}
                              onChange={(value) =>
                                this.setState({ plan_id: value.value })
                              }
                              options={this.state.plans}
                              placeholder="Select Plan / சந்தாவைத் தேர்ந்தெடுக்கவும்"
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Plan",
                            this.state.plan_id,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-end my-2">
                        <Col sm="8">
                          <CardTitle>Add Extra Channels / சேனல்களைச் சேர்க்கவும்</CardTitle>
                        </Col>
                        <Col sm="3">
                          <Button className="btn-round" color="success" onClick={this.addChennels}>
                            <i className="tim-icons icon-check-2" />     add / சேர்
                          </Button>
                        </Col>
                      </Row>
                      <Row className="justify-content-end my-2">
                        {this.state.extra_chennel.length > 0 && this.state.extra_chennel.map((data, index) => (
                          <>
                            <Col sm="5" key={`rec_${index}`}>
                              <FormGroup>
                                {/* <Input
                                  name={`extra_chennel_${index}`}
                                  value={this.state.extra_chennel[index].chennel}
                                  onChange={(e) => {
                                    let temp = this.state.extra_chennel
                                    temp[index].chennel = e.target.value;
                                    this.setState({ extra_chennel: temp })
                                  }}
                                  placeholder="Chennel"
                                /> */}

                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  name={`extra_chennel_${index}`}
                                  value={this.state.channels.find(
                                    (data) => data.label === this.state.extra_chennel[index].chennel
                                  )}
                                  onChange={(e) => {
                                    let temp = this.state.extra_chennel
                                    temp[index].chennel = e.label;
                                    temp[index].value = e.value;
                                    this.setState({ extra_chennel: temp })
                                  }}
                                  options={this.state.channels}
                                  placeholder="Select Chennel / சேனலைத் தேர்ந்தெடுக்கவும்"
                                />
                              </FormGroup>
                              {this.validator.message(
                                "Extra chennel",
                                this.state.extra_chennel[index].chennel,
                                "required",
                                { className: "text-danger" }
                              )}
                            </Col>
                            <Col sm="5" key={`recVal_${index}`}>
                              <FormGroup>
                                <Input
                                  name={`extra_chennel_value_${index}`}
                                  value={this.state.extra_chennel[index].value}
                                  onChange={(e) => {
                                    let temp = this.state.extra_chennel
                                    temp[index].value = e.target.value;
                                    this.setState({ extra_chennel: temp })
                                  }}
                                  placeholder="Channel Price / சேனல் விலை"
                                  disabled
                                />
                              </FormGroup>
                              {this.validator.message(
                                "Extra chennel",
                                this.state.extra_chennel[index].value,
                                "required",
                                { className: "text-danger" }
                              )}
                            </Col>
                            <Col md="1" sm="1">
                              <Button className="btn-icon btn-round" color="google" onClick={() => this.removeChennels(index)}>
                                <i className="tim-icons icon-simple-remove btn-round" />
                              </Button>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.update}>
                      {this.props.match.params.id ? 'Update / புதுப்பி' : 'create / உருவாக்கு'}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    listPlan: state.plan.listPlan,
    listChannel: state.channel.listChannel,
    listUser: state.user.operators,
    addUserProps: state.user.addUser,
  };
};

export default connect(mapStateToProps, {
  getUserById,
  updateUserById,
  addUser,
  getPlan,
  getChannel,
  getUsers
})(EditUser);
