import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
  Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap";
import Select from "react-select";
//import actions
import { getTransactions, updateTransactionStatus } from "../../actions";

class ListPayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      count: 10,
      perPage: 10,
      loading: true,
      operators: [],
      operator_id: '',
      UpdateTransactionCompletedModal: false,
      transactionUpdatedData: {
        _id: "",
        status: "success",
        ref_id: ""
      },
      tableColumns : [
        {
          Header: "ID / ஐடி",
          accessor: "id",
        },
        {
          Header: "User Name / பயனர் பெயர்",
          accessor: "user_name",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Amount / தொகை",
          accessor: "amount",
          sortable: false,
        },
        {
          Header: "Status / நிலை",
          accessor: "process_status",
        },
        {
          Header: "Created At / பரிவர்த்தனை தேதி",
          accessor: "create_date",
        },
        {
          Header: "Actions / நடவடிக்கை",
          accessor: "actions",
          sortable: false,
          filterable: false,
        },
      ]
    };
  }

  componentDidMount(props){
    this.setState({
      tableColumns: this.state.tableColumns.filter(column => {
        if (this.props.user.login
          && this.props.user.login._id
          && this.props.user.login.role !== 'superadmin'
          && (["actions","user_name"].includes(column.accessor))) {
          return false
        }
        return true
      })
    })
  }
  ShowModelToggle = () => {
    if (this.state.UpdateTransactionCompletedModal) {
      this.setState({
        transactionUpdatedData: {
          _id: "",
          status: "success",
          ref_id: ""
        }
      })
    }
    this.setState({ UpdateTransactionCompletedModal: !this.state.UpdateTransactionCompletedModal })
  };

  loadData = (data, pageInfo) => {
    this.setState({
      data: data.map((prop, key) => {
        var paymentDetails = getPaymentStatusDetails();
        return {
          id: prop._id,
          brand_name: prop.User.brand_name,
          user_name: prop.User.fullname,
          amount: prop.amount,
          process_status: (<div style={{ color: paymentDetails.color }}>{paymentDetails.statusStr}</div>),
          create_date: prop.create_date,
          actions: (paymentDetails.showAction ?
            <div className="actions-right">
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.setState({
                    UpdateTransactionCompletedModal: true,
                    transactionUpdatedData: {
                      _id: prop._id,
                      status: "success",
                      ref_id: prop.ref_id
                    }
                  })
                }
                }
              >
                Update Status
              </Button>
            </div> : <></>
          )
        };

        function getPaymentStatusDetails() {
          switch (`${prop.process_status || ""}`.toLowerCase()) {
            case "success":
              return { showAction: false, statusStr: "Payment request Completed", color: "green" };
            case "pending":
              return { showAction: true, statusStr: "Payment request Initiated", color: "red" };
            default:
              return { showAction: true, statusStr: prop.process_status, color: "black" };
          }
        }

      }),
      count: pageInfo.pageCount,
      perPage: pageInfo.perPage,
      loading: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.list !== prevProps.list &&
      this.props.list.transactionPagination &&
      this.props.list.transactionPagination.items
    ) {
      this.loadData(
        this.props.list.transactionPagination.items,
        this.props.list.transactionPagination.pageInfo
      );
    }
    if (
      this.props.listOperators !== prevProps.listOperators &&
      this.props.listOperators.userPagination &&
      this.props.listOperators.userPagination.items
    ) {
      let items = [];
      this.props.listOperators.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      this.setState({ operators: items });
    }
  }

  onFetchData = (state, instance) => {
    // show the loading overlay
    this.setState({ loading: true });
    let payload = {
      page: state.page + 1,
      perPage: state.pageSize,
      filter: {
        gateway: "OFFLINE",
        status: 1
      },
      sort: "_ID_DESC"
    };
    if (state.filtered.length) {
      state.filtered.forEach((data) => {
        payload.filter[data.id] = parseFloat(data.value) || data.value;
      });
    }
    if (this.props.user.login && this.props.user.login._id && this.props.user.login.role !== 'superadmin') {
      payload.filter.user_id = this.props.user.login._id;
    }
    if (state.filter) {
      payload.filter = payload.filter ? { ...payload.filter, ...state.filter } : state.filter
    }
    if (state.sorted.length)
      payload.sort =
        state.sorted[0].id.toUpperCase() +
        (!state.sorted[0].desc ? "_ASC" : "_DESC");
    this.props.getTransactions(payload);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  operatorOnChangeHandler = (value) => {
    this.setState({ operator_id: value.value })
    this.onFetchData({
      ...this.state, filter: {
        user_id: value.value
      },
      sorted: [],
      filtered: []
    })
  }

  saveTransactionStatus = () => {
    if (this.state.transactionUpdatedData && this.state.transactionUpdatedData._id) {
      this.props.updateTransactionStatus(this.state.transactionUpdatedData);
      this.setState({
        data:
          this.state.data.map((record) => {
            if (record.id == this.state.transactionUpdatedData._id) {
              record.process_status = this.state.transactionUpdatedData.status
              record.actions = (<></>)
            }
            return record
          })
      });
    }
    this.ShowModelToggle();

  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">List Payout</CardTitle>
                    </Col>
                    <Col sm="5">
                      <FormGroup>
                        <Select
                          className={`react-select info ${this.props.user &&
                              this.props.user.login &&
                              this.props.user.login._id &&
                              this.props.user.login.role !== "superadmin" ? "d-none" : ''}`}
                          classNamePrefix="react-select"
                          name="operator_id"
                          value={this.state.operators.find(
                            (data) =>
                              data.value === this.state.operator_id
                          )}
                          onChange={this.operatorOnChangeHandler}
                          options={this.state.operators}
                          placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    loading={this.state.loading}
                    resizable={false}
                    columns={this.state.tableColumns}
                    defaultPageSize={this.state.perPage}
                    // page={this.state.page}
                    pages={this.state.count}
                    onFetchData={(state, instance) =>
                      this.onFetchData(state, instance)
                    }
                    manual
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{
          display: 'block', width: 700, padding: 30
        }}>
          <Modal isOpen={this.state.UpdateTransactionCompletedModal} toggle={this.ShowModelToggle}>
            <ModalHeader
              toggle={this.ShowModelToggle}>Payment Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to update payment status as completed
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.saveTransactionStatus}>Yes</Button>
              <Button color="danger" onClick={this.ShowModelToggle}>No, cancel</Button>
            </ModalFooter>
          </Modal>
        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.transaction.list,
  user: state.user.login,
  listOperators: state.user.operators,
});

export default connect(mapStateToProps, {
  getTransactions,
  updateTransactionStatus
})(ListPayout);
