export const channelCreate = {
    query: `mutation channelCreate(
        $record: CreateOneChannelInput!
        ){
        channelCreate(
        record: $record
        ){
            record {
                name
                price
                operator_id
                status
          }
        }
    }`,
};

export const channelPagination = {
    query: `query channelPagination(
        $page: Int
        $perPage: Int = 20
        $filter: FilterFindManyChannelInput
        $sort: SortFindManyChannelInput
      ) {
        channelPagination(
          page: $page
          perPage: $perPage
          filter: $filter
          sort: $sort
        ) {
          count
          pageInfo {
            pageCount
            perPage
            currentPage
          }
          items {
              _id
            name
            price
            operator_id
            status
          }
        }
    }`,
};

export const channelById = {
    query: `query channelById($id: MongoID!) {
        channelById(_id: $id) {
          name
          _id
          price
          operator_id
          created_at
          updated_at
          status
        }
    }`,
};

export const channelUpdateById = {
    query: `mutation channelUpdateById(
        $record: UpdateByIdChannelInput!
        ){
        channelUpdateById(
        record: $record
        ){
            record {
                name
                price
                operator_id
                status
          }
        }
    }`,
};
