export const NotificationPagination = {
    query: `query NotificationPagination(
        $page: Int
        $perPage: Int = 20
        $filter: FilterFindManyNotificationInput
        $sort: SortFindManyNotificationInput
      ) {
        NotificationPagination(
          page: $page
          perPage: $perPage
          filter: $filter
          sort: $sort
        ) {
          count
          items {
            ref_id
            msg
            type
            ref_us
            create_date
          }
        }
    }`,
};

export const NotificationUpdateMany = {
    query: `mutation NotificationUpdateMany(
        $record: UpdateManyNotificationInput!
        $filter: FilterUpdateManyNotificationInput
        $sort: SortUpdateManyNotificationInput
      ) {
        NotificationUpdateMany(record: $record, filter: $filter, sort: $sort) {
          numAffected
        }
    }`,
};
