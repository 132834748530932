import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

//import actions
import { getUsers } from "../../actions";

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      count: 10,
      perPage: 10,
      loading: true,
    };
  }

  loadData = (data, pageInfo) => {
    this.setState({
      data: data.map((prop, key) => {
        return {
          phone: prop.phone,
          fullname: prop.fullname,
          email: prop.email,
          brand_name: prop.brand_name,
          address: prop.address,
          wallet_payment: prop.wallet_payment,
          actions: (
            <div className="actions-right">
              <Link to={`/admin/edit-operator/${prop._id}`}>
                <Button
                  color="warning"
                  size="sm"
                  className={classNames("btn-icon btn-link like", {
                    "btn-neutral": key < 5,
                  })}
                >
                  <i className="tim-icons icon-pencil" />
                </Button>
              </Link>
            </div>
          ),
        };
      }),
      count: pageInfo.pageCount,
      perPage: pageInfo.perPage,
      loading: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.listUser !== prevProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      this.loadData(
        this.props.listUser.userPagination.items,
        this.props.listUser.userPagination.pageInfo
      );
    }
  }

  onFetchData = (state, instance) => {
    // show the loading overlay
    this.setState({ loading: true });
    if (state.sorted.length) console.log(state.sorted[0].id.toUpperCase());
    let payload = {
      page: state.page + 1,
      perPage: state.pageSize,
      filter: {
        isUser: false,
        role: "operator"
      },
      sort: "_ID_DESC"
    };
    if (state.filtered.length) {
      state.filtered.forEach((data) => {
        payload.filter[data.id] = `${data.value}`;
      });
    }
    if (state.sorted.length)
      payload.sort =
        state.sorted[0].id.toUpperCase() +
        (!state.sorted[0].desc ? "_ASC" : "_DESC");
    this.props.getUsers(payload);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  render() {
    return (
      <>
        <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">List Users / பயனர்களின் பட்டியல்</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    loading={this.state.loading}
                    resizable={false}
                    columns={[
                      {
                        Header: "Full Name / முழு பெயர்",
                        accessor: "fullname",
                      },
                      {
                        Header: "Company Name / நிறுவனத்தின் பெயர்",
                        accessor: "brand_name",
                      },
                      {
                        Header: "Mobile / கைபேசி",
                        accessor: "phone",
                      },
                      {
                        Header: "Email / மின்னஞ்சல்",
                        accessor: "email",
                      },
                      {
                        Header: "Address / முகவரி",
                        accessor: "address",
                      },
                      {
                        Header: "Wallet Blance / பணப்பை இருப்பு",
                        accessor: "wallet_payment",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Actions / நடவடிக்கை",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={this.state.perPage}
                    // page={this.state.page}
                    pages={this.state.count}
                    onFetchData={(state, instance) =>
                      this.onFetchData(state, instance)
                    }
                    manual
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listUser: state.user.listUser,
});

export default connect(mapStateToProps, {
  getUsers,
})(ListUser);
