import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import { getUserById, updateUserById, addUser } from "actions";
import { connect } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

const initState = {
  fullName: "",
  countryCode: "",
  phone: "",
  zipCode: "",
  deviceId: "",
  fbId: "",
  twiterId: "",
  emailId: "",
  currency: { value: "INR", label: "INR", selected: true },
  lastverified: "",
  location: "",
  address: "",
  password: "",
  brand_name: "",
  logo: "",
  role: "operator",
  street_address: "",
  apartment_number: "",
  passWrd: true,
  account: '',
  bankAccount: "",
  bankIFSCCode: "",
  bankName: "",
  isBankEnabled: false,
  adminCommision: ""
};

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = initState
  }

  chageDateFormat = (date) => {
    let newDateFormat = moment(date, "YYYY-MM-DD HH:mm:ss").format(
      "YYYY-MM-DD"
    );
    return newDateFormat;
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      let payload = {
        id: this.props.match.params.id,
      };
      this.props.getUserById(payload);
    }
  };

  componentDidUpdate(previousProps) {
    if (this.props.user.user !== previousProps.user.user) {
      let data = this.props.user.user.userById;
      this.setState({
        fullName: data.fullname,
        countryCode: data.country_code,
        phone: data.phone,
        zipCode: data.zipcode,
        deviceId: data.device_id,
        fbId: data.fb_id,
        twiterId: data.twitter_id,
        emailId: data.email,
        password: data.password,
        Currency: { value: "INR", label: "INR", selected: true },
        lastverified: data.last_verified,
        onlineStatus: data.isOnline,
        address: data.address.split(",")[2],
        street_address: data.address.split(",")[1],
        apartment_number: data.address.split(",")[0],
        brand_name: data.brand_name,
        logo: data.logo,
        account: data.account,
        role: "operator",
        bankAccount: data.bank_account_number || "",
        bankIFSCCode: data.bank_ifsc || "",
        bankName: data.bank_name || "",
        isBankEnabled: data.isBankEnabled || false,
        adminCommision: data.adminCommision || ""
      });
    }
    if (this.props.user.addUser !== previousProps.user.addUser) {
      this.props.history.push('/list-operator/')
    }
    if (
      this.props.addUserProps !== previousProps.addUserProps
    ) {
      this.setState(initState)
      this.validator.hideMessages();
    }
  }

  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }

  update = async () => {
    if (this.validator.allValid()) {
      let payload = {
        record: {
          fullname: this.state.fullName,
          country_code: this.state.countryCode,
          phone: this.state.phone,
          zipcode: this.state.zipCode,
          device_id: this.state.deviceId,
          fb_id: this.state.fbId,
          twitter_id: this.state.twiterId,
          email: this.state.emailId,
          address:
            this.state.apartment_number +
            "," +
            this.state.street_address +
            "," +
            this.state.address,
          brand_name: this.state.brand_name,
          logo: this.state.logo,
          isUser: false,
          role: "operator",
          account: this.state.account,
          bank_name: this.state.bankName,
          bank_account_number: this.state.bankAccount,
          bank_ifsc: this.state.bankIFSCCode,
          isBankEnabled: this.state.isBankEnabled,
          adminCommision: parseFloat(this.state.adminCommision)
        },
      };
      if (this.props.match.params.id) {
        payload.filter = {
          _id: this.props.match.params.id,
        };
        this.props.updateUserById(JSON.stringify(payload));
      } else {
        payload.record.password = this.state.password
        this.props.addUser(JSON.stringify(payload));
        // this.setState(initState)
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleImageCgange = (value) => this.setState({ logo: value });

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{`${this.props.match.params.id ? "Update Operator / புதுப்பிப்பு ஆபரேட்டர்" : "Create Operator / புதிய ஆபரேட்டர் பதிவு"
                      }`}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column mt-5">
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Full Name / முழு பெயர்"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "fullName");
                              }}
                              value={this.state.fullName}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Full Name",
                            this.state.fullName,
                            "alpha_space|min:3|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Company Name / நிறுவனத்தின் பெயர்"
                              type="text"
                              value={this.state.brand_name}
                              onChange={(e) => {
                                this.chage(e, "brand_name");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Company Name",
                            this.state.brand_name,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>

                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Account ID"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "account");
                              }}
                              value={this.state.account}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Account ID",
                            this.state.account,
                            "string",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Phone / தொலைபேசி எண்"
                              type="text"
                              value={this.state.phone}
                              onChange={(e) => {
                                this.chage(e, "phone");
                              }}
                              maxLength={10}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Phone",
                            this.state.phone,
                            "min:10|max:10|numeric|min:0,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Email / மின்னஞ்சல் முகவரி"
                              value={this.state.emailId}
                              onChange={(e) => {
                                this.chage(e, "emailId");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Email",
                            this.state.emailId,
                            "required|email",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              placeholder="Zip Code / அஞ்சல் குறியீடு"
                              value={this.state.zipCode}
                              onChange={(e) => {
                                this.chage(e, "zipCode");
                              }}
                              maxLength={6}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Zip Code",
                            this.state.zipCode,
                            "min:6|max:6|numeric|min:0,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Apartment Number / அபார்ட்மெண்ட் எண்"
                              type="text"
                              value={this.state.apartment_number}
                              onChange={(e) => {
                                this.chage(e, "apartment_number");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Apartment Number",
                            this.state.apartment_number,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Address / முகவரி"
                              value={this.state.street_address}
                              onChange={(e) => {
                                this.chage(e, "street_address");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Street Address",
                            this.state.street_address,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>

                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="State / மாநிலம்"
                              type="text"
                              onChange={(e) => {
                                this.chage(e, "address");
                              }}
                              value={this.state.address}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "State",
                            this.state.address,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <div className="pwdWrpr w-100">
                            <FormGroup>
                              <Input
                                placeholder="Password / கடவுச்சொல்"
                                type={this.state.passWrd ? 'password' : 'text'}
                                onChange={(e) => {
                                  this.chage(e, "password");
                                }}
                                value={this.state.password}
                                disabled={
                                  this.props.match.params.id ? true : false
                                }
                              />
                              {this.state.passWrd ?
                                <i className="pwdIcon fa fa-eye" aria-hidden="true" onClick={() => this.setState({ passWrd: false })}></i> :
                                <i className="pwdIcon fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ passWrd: true })}></i>
                              }
                            </FormGroup>
                          </div>
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "min:6|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Bank Name / வங்கி பெயர்"
                              type="text"
                              value={this.state.bankName}
                              onChange={(e) => {
                                this.chage(e, "bankName");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Bank Name",
                            this.state.bankName,
                            (this.state.isBankEnabled ? "required" : ""),
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Bank Account Number / வங்கி கணக்கு எண்"
                              type="text"
                              value={this.state.bankAccount}
                              onChange={(e) => {
                                this.chage(e, "bankAccount");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Bank Account Number",
                            this.state.bankAccount,
                            (this.state.isBankEnabled ? "required" : ""),
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Bank IFSC Code / வங்கி IFSC குறியீடு"
                              value={this.state.bankIFSCCode}
                              onChange={(e) => {
                                this.chage(e, "bankIFSCCode");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Bank ifsc Code",
                            this.state.bankIFSCCode,
                            (this.state.isBankEnabled ? "required" : ""),
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              placeholder="Admin Fee (%) / நிர்வாக கட்டணம் (%)"
                              value={this.state.adminCommision}
                              onChange={(e) => {
                                this.chage(e, "adminCommision");
                              }}
                              min={0}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Admin Fee",
                            this.state.adminCommision,
                            "min:0|max:99|numeric|min:0,num|max:99,num|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col sm="9">
                          <FormGroup>
                            <Label style={{ cursor: "pointer" }}>
                              <Input
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                checked={this.state.isBankEnabled}
                                onChange={(event) => { this.setState({ isBankEnabled: event.target.checked }); }}
                              /> Send Payments to Above Bank Account / மேலே உள்ள வங்கிக் கணக்கிற்கு பணம் அனுப்பவும்
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center my-2">
                        <Col md="6" sm="4">
                          <CardTitle tag="h4">Logo / சின்னம்</CardTitle>
                          <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            onChange={this.handleImageCgange}
                            imagePreviewUrl={this.state.logo ? process.env.REACT_APP_BACKEND_URL.replace('/api', '') + 'uploads/logo/' + this.state.logo : ''}
                          />
                        </Col>
                        {this.validator.message(
                          "Logo",
                          this.state.logo,
                          "string",
                          { className: "text-danger" }
                        )}
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.update}>
                      {this.props.match.params.id ? 'Update / புதுப்பி' : 'create / உருவாக்கு'}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    addUserProps: state.user.addUser,
  };
};

export default connect(mapStateToProps, {
  getUserById,
  updateUserById,
  addUser
})(EditUser);
