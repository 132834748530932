import { takeLatest, call, put } from "redux-saga/effects";

import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import { NotificationPagination } from "../schema/notification";
import { getNotificationResponse } from "../actions";

export function* getNotificationEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...NotificationPagination, variables: payload })
    );
    yield put(getNotificationResponse(data.data));
  } catch (e) {
    yield put(getNotificationResponse(e));
  }
}

export function* getNotificationWatcherSaga() {
  yield takeLatest(types.GET_NOTIFICATION_LIST, getNotificationEffectSaga);
}