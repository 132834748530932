export const planCreate = {
  query: `mutation planCreate($record: CreateOnePlanInput!) {
    planCreate(record: $record) {
      record {
        amount
				operator_id
				status
      }
    }
  }
  `,
};

export const listPlan = {
  query: `query planPagination(
        $filter: FilterFindManyPlanInput
        $perPage: Int = 20
        $page: Int
        $sort: SortFindManyPlanInput
      ) {
        planPagination(filter: $filter, perPage: $perPage, page: $page, sort: $sort) {
          items {
            _id
            plan_id
            operator_id
            amount
            status
            name
            type
            box
            User {
              address
              logo
              brand_name
              fullname
            }
          }
          count
          pageInfo {
            perPage
            currentPage
            pageCount
          }
        }
      }
      `,
};

export const getPlan = {
  query: `query planById($id: MongoID!) {
    planById(_id: $id) {
      _id
      operator_id
      amount
      status
      name
      type
      box
    }
  }`,
};

export const updatePlanDetialsById = {
  query: `mutation planUpdateById($record: UpdateByIdPlanInput!) {
    planUpdateById(record: $record) {
      record {
        plan_id
      }
    }
  }`,
};
