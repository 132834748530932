import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import {
  getSinglePlan,
  getUsers,
  updatePlanDetailsById,
  createPlan,
} from "actions";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

const initState = {
  operator_id: "",
  name: "",
  type: "",
  box: "",
  amount: "",
  status: 1,
  typeList: [{ label: "HD", value: "hd" }, { label: "NORMAL", value: "normal" }]
};

class PlanUser extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = { ...initState, operators: [], }
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      let payload = {
        id: this.props.match.params.id,
      };
      this.props.getSinglePlan(payload);
    }
    // this.props.getUsers({ filter: { isUser: false } });
  };
  componentDidUpdate(previousProps) {
    if (
      this.props.plan &&
      this.props.plan !== previousProps.plan &&
      this.props.plan.planById
    ) {
      let data = this.props.plan.planById;
      this.setState({
        operator_id: data.operator_id,
        amount: data.amount,
        status: data.status,
        name: data.name,
        type: data.type,
        box: data.box,
      });
    }
    if (
      this.props.listUser !== previousProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      let items = [];
      this.props.listUser.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      if (
        this.props.user.login &&
        this.props.user.login._id &&
        this.props.user.login.role !== "superadmin"
      ) {
        this.setState({ operator_id: this.props.user.login._id });
      } else {
        this.setState({ operators: items });
      }
    }
    if (this.props.createPlanRes !== previousProps.createPlanRes) {
      let operator_id = this.state.operator_id === this.props.user.login._id ? this.props.user.login._id : false
      this.setState(initState)
      operator_id && this.setState({ operator_id })
      this.validator.hideMessages();
    }
  }
  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  update = async () => {
    console.log(this.validator.allValid(), this.props.match.params.id)
    if (this.validator.allValid()) {
      let payload = {
        record: {
          name: this.state.name,
          type: this.state.type,
          box: this.state.box,
          operator_id: this.state.operator_id,
          amount: parseFloat(this.state.amount),
          status: this.state.status,
        },
      };
      if (this.props.match.params.id)
        payload.record._id = this.props.match.params.id;

      if (this.props.match.params.id) {
        this.props.updatePlanDetailsById(payload);
      } else {
        this.props.createPlan(payload);

      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Form id="RegisterValidation">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{`${this.props.match.params.id ? "Update Plan / சந்தா புதுப்பிப்பு" : "Create Plan / சந்தாவை உருவாக்கவும்"
                      }`}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column mt-5">
                      <Row className="justify-content-center my-2">
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Name / பெயர்"
                              type="text"
                              value={this.state.name}
                              onChange={(e) => {
                                this.chage(e, "name");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Name",
                            this.state.name,
                            "string|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            {/* <Input
                              placeholder="Type"
                              type="text"
                              value={this.state.type}
                              onChange={(e) => {
                                this.chage(e, "type");
                              }}
                            /> */}
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              name="type"
                              value={this.state.typeList.find(
                                (data) =>
                                  data.value === this.state.type
                              )}
                              onChange={(value) =>
                                this.setState({ type: value.value })
                              }
                              options={this.state.typeList}
                              placeholder="Select Type / வகையைத் தேர்ந்தெடுக்கவும்"
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Type",
                            this.state.type,
                            "string|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Box Name / பெட்டியின் பெயர்"
                              type="text"
                              value={this.state.box}
                              onChange={(e) => {
                                this.chage(e, "box");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Box Name",
                            this.state.box,
                            "string|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              placeholder="Amount / தொகை"
                              type="number"
                              value={this.state.amount}
                              onChange={(e) => {
                                this.chage(e, "amount");
                              }}
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Amount",
                            this.state.amount,
                            "numeric|required",
                            { className: "text-danger" }
                          )}
                        </Col>
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              // className={`react-select info ${this.state.operator_id === this.props.user.login._id ? "d-none" : ''}`}
                              className={`react-select info ${this.props.user.login.role == "operator" ? "d-none" : ''}`}
                              classNamePrefix="react-select"
                              name="operator_id"
                              value={this.state.operator_id && this.state.operators.find(
                                (data) =>
                                  data.value === this.state.operator_id
                              )}
                              onChange={(value) =>
                                this.setState({ operator_id: value.value })
                              }
                              options={this.state.operators}
                              placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                            />
                          </FormGroup>
                          {this.validator.message(
                            "Operator id",
                            this.state.operator_id,
                            "required",
                            { className: "text-danger" }
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="primary" onClick={this.update}>
                      {this.props.match.params.id ? 'Update / புதுப்பி' : 'create / உருவாக்கு'}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan: state.plan.singlePlan,
    createPlanRes: state.plan.createPlan,
    listUser: state.user.operators,
    user: state.user.login,
  };
};

export default connect(mapStateToProps, {
  getSinglePlan,
  getUsers,
  createPlan,
  updatePlanDetailsById,
})(PlanUser);
