import {
  LIST_USER_RESPONSE,
  GET_USER_BY_ID_RESPONSE,
  UPDATE_USER_BY_ID_RESPONSE,
  ADD_USER_RESPONSE,
  LOGO_UPLOAD_RESPONSE,
  LOGIN_USER_RESPONSE,
  COUNT_RESPONSE,
  GET_NOTIFICATION_RESPONSE,
  LIST_OPERATORS_RESPONSE
} from "../constants/ActionTypes";

import { getCookie } from "util/cookies";

const initialState = {
  listUser: [],
  addUser: {},
  logoUpload: {},
  login:
    getCookie("__user") && getCookie("__user") !== ""
      ? { login: JSON.parse(getCookie("__user")) }
      : [],
  count: {
    totaluser: 0,
    thisMonthUser: 0,
    thisMounthPaidUser: 0,
    thisMounthUnpaidUser: 0,
  },
  notifications: [],
  operators: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_RESPONSE:
      return { ...state, listUser: action.data };
    case ADD_USER_RESPONSE:
      return { ...state, addUser: action.data };
    case GET_USER_BY_ID_RESPONSE:
      return { ...state, user: action.data };
    case UPDATE_USER_BY_ID_RESPONSE:
      return { ...state, updateUser: action.data };
    case LOGO_UPLOAD_RESPONSE:
      return { ...state, logoUpload: action.data };
    case LOGIN_USER_RESPONSE:
      return { ...state, login: action.data };
    case COUNT_RESPONSE:
      return { ...state, count: action.data };
    case GET_NOTIFICATION_RESPONSE:
      return { ...state, notifications: action.data };
    case LIST_OPERATORS_RESPONSE:
      return { ...state, operators: action.data };
    default:
      return state;
  }
};
export default userReducer;
