import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { getChannelResponse, getSingleChannelResponse, createChannelResponse } from "../actions";
import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import { channelCreate, channelPagination, channelById, channelUpdateById } from "../schema/channel";

export function* listChannelEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...channelPagination, variables: payload })
    );
    yield put(getChannelResponse(data.data));
  } catch (e) {
    yield put(getChannelResponse([]));
  }
}

export function* singleChannelEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...channelById, variables: payload })
    );
    yield put(getSingleChannelResponse(data.data));
  } catch (e) {
    console.log(e);
    yield put(getSingleChannelResponse([]));
  }
}

export function* updateChannelDetialsEffectSaga({ payload, reslove, reject }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...channelUpdateById, variables: payload })
    );

    if (data.errors) toast.success("Update failed.");
    else toast.success("Successfully updated.")
  } catch (e) {
    yield reslove(e);
  }
}

export function* createChannelEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...channelCreate, variables: payload })
    );
    if (data.data.channelCreate) {
      yield put(createChannelResponse({ data: { res: 'success', ...data } }));
      yield toast.success("Successfully added.");
    }
    else yield toast.error("Channel alredy exists.");
  } catch (e) {
    yield toast.error(e.toString());
  }
}

export function* listChannelWatcherSaga() {
  yield takeLatest(types.LIST_CHANNEL, listChannelEffectSaga);
}

export function* singleChannelWatcherSaga() {
  yield takeLatest(types.SINGLE_CHANNEL, singleChannelEffectSaga);
}

export function* updateChannelByIdWatcherSaga() {
  yield takeLatest(
    types.UPDATE_CHANNEL_DETAILS_BY_ID,
    updateChannelDetialsEffectSaga
  );
}

export function* createChannelWatcherSaga() {
  yield takeLatest(types.CREATE_CHANNEL, createChannelEffectSaga);
}
