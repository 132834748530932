export const listUser = {
  query: `
  query userPagination(
    $filter: FilterFindManyUserInput
    $perPage: Int
    $page: Int
    $sort: SortFindManyUserInput
  ) {
    userPagination(filter: $filter, perPage: $perPage, page: $page, sort: $sort) {
      count
      items {
        _id
        fullname
        phone
        email
        address
        device_id
        isUser
        brand_name
        wallet_payment
        status
        account
        extra_chennel {
          chennel
          value
        }
        extra_chennel_total
        Subscription {
          next_pay_amount 
          lat_pay_amount
          pending_pay_amount
          _id
          Plan {
            name
            amount
            status
            operator_id
            User {
              _id
              address
              brand_name
              logo
              phone
              email
            }
          }
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
      }
    }
  }
`,
};

export const userById = {
  query: `
  query userById($id: MongoID!) {
    userById(_id: $id) {
      token
      fullname
      phone
      email
      address
      isUser
      zipcode
      country_code
      currency
      password
      device_id
      operator_id
      fcm
      fb_id
      twitter_id
      logo
      brand_name
      status
      wallet_payment
      _id
      role
      account
      bank_name
      bank_ifsc
      bank_account_number
      isBankEnabled
      extra_chennel {
        chennel
        value
      }
      plan_id
      extra_chennel_total
      adminCommision
      Subscription {
        next_pay_amount
        lat_pay_amount
        pending_pay_amount
        Plan {
          amount
          status
          User {
            address
            brand_name
            logo
            phone
            email
          }
        }
      }
    }
  }
`,
};

export const userCount = {
  query: `
  query userCount($filter: FilterUserInput) {
    userCount(filter: $filter)
  }
`,
};

export const register = {
  query: `
  mutation register($record: CreateOneUserInput!) {
    register(record: $record) {
      record {
        email
        phone
        isUser
        _id
      }
    }
  }
`,
};

export const login = {
  query: `
  query login(
    $email: String
    $password: String
    $fcm: String!
    $deviceid: String!
    $isUser: Boolean!
  ) {
    login(
      email: $email
      password: $password
      fcm: $fcm
      deviceid: $deviceid
      isUser: $isUser
    ) {
      token
      fullname
      phone
      email
      brand_name
      address
      role
      isUser
      operator_id
      wallet_payment
      _id
      account
      extra_chennel {
        chennel
        value
      }
      extra_chennel_total
      Subscription {
        next_pay_amount
        lat_pay_amount
        pending_pay_amount
        Plan {
          amount
          status
          User {
            address
            brand_name
            logo
            phone
            email
          }
        }
      }
    }
  }
`,
};

export const userUpdateOne = {
  query: `
  mutation userUpdateOne(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
  ) {
    userUpdateOne(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`,
};

export const userPagination = {
  query: `
  query userPagination(
    $page: Int
    $perPage: Int = 20
    $filter: FilterFindManyUserInput
  ) {
    userPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      pageInfo {
        currentPage
      }
      items {
        token
        fullname
        phone
        email
        address
        isUser
        account
        extra_chennel {
          chennel
          value
        }
        extra_chennel_total
        Subscription {
          next_pay_amount
          lat_pay_amount
          pending_pay_amount
          Plan {
            amount
            status
            User {
              address
              brand_name
              logo
              phone
              email
            }
          }
        }
      }
    }
  }
`,
};

export const logoUpload = {
  query: `
  mutation logoUpload($file: Upload!) {
    logoUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`,
};

export const count = {
  query: `query count($user_id: MongoID) {
  count(user_id: $user_id) {
    totaluser
    thisMonthUser
    thisMounthPaidUser
    thisMounthUnpaidUser
    totalDeactiveUser
  }
}`,
};
