import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup } from "reactstrap";
import Select from "react-select";
//import actions
import { getTransactions } from "../../actions";

class ListTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      count: 10,
      perPage: 10,
      loading: true,
      operators: [],
      operator_id: '',
    };
  }

  loadData = (data, pageInfo) => {
    this.setState({
      data: data.map((prop, key) => {
        return {
          id: prop._id,
          brand_name: prop.User.brand_name,
          user_name: prop.User.fullname,
          address: prop.User.address,
          amount: prop.amount,
          process_status: getPaymentStatusStr(),
          create_date: prop.create_date,
        };

        function getPaymentStatusStr() {
          switch (`${prop.process_status || ""}`.toLowerCase()) {
            case "in_progress":
              return "Inprogress"
            default:
              return prop.process_status;
          }
        }
      }),
      count: pageInfo.pageCount,
      perPage: pageInfo.perPage,
      loading: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.list !== prevProps.list &&
      this.props.list.transactionPagination &&
      this.props.list.transactionPagination.items
    ) {
      this.loadData(
        this.props.list.transactionPagination.items,
        this.props.list.transactionPagination.pageInfo
      );
    }
    if (
      this.props.listOperators !== prevProps.listOperators &&
      this.props.listOperators.userPagination &&
      this.props.listOperators.userPagination.items
    ) {
      let items = [];
      this.props.listOperators.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      this.setState({ operators: items });
    }
  }

  onFetchData = (state, instance) => {
    // show the loading overlay
    this.setState({ loading: true });
    let payload = {
      page: state.page + 1,
      perPage: state.pageSize,
      filter: {
        OR: [
          { gateway: "MANUAL", status: 1 },
          { gateway: "RAZORPAY", status: 1 },
        ]
      },
      sort: "_ID_DESC"
    };
    if (state.filtered.length) {
      state.filtered.forEach((data) => {
        payload.filter[data.id] = parseFloat(data.value) || data.value;
      });
    }
    if (this.props.user.login && this.props.user.login._id && this.props.user.login.role !== 'superadmin') {
      payload.filter.operator_id = this.props.user.login._id;
    }
    if (state.filter) {
      payload.filter = payload.filter ? { ...payload.filter, ...state.filter } : state.filter
    }
    if (state.sorted.length)
      payload.sort =
        state.sorted[0].id.toUpperCase() +
        (!state.sorted[0].desc ? "_ASC" : "_DESC");
    this.props.getTransactions(payload);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  operatorOnChangeHandler = (value) => {
    this.setState({ operator_id: value.value })
    this.onFetchData({
      ...this.state, filter: {
        operator_id: value.value
      },
      sorted: [],
      filtered: []
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">List Transactions</CardTitle>
                    </Col>
                    <Col sm="5">
                      <FormGroup>
                        <Select
                          className={`react-select info ${
                            this.props.user &&
                            this.props.user.login &&
                            this.props.user.login._id &&
                            this.props.user.login.role !== "superadmin" ? "d-none" : ''}`}
                          classNamePrefix="react-select"
                          name="operator_id"
                          value={this.state.operators.find(
                            (data) =>
                              data.value === this.state.operator_id
                          )}
                          onChange={this.operatorOnChangeHandler}
                          options={this.state.operators}
                          placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    loading={this.state.loading}
                    resizable={false}
                    columns={[
                      {
                        Header: "ID / ஐடி",
                        accessor: "id",
                      },
                      {
                        Header: "User Name / பயனர் பெயர்",
                        accessor: "user_name",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Address / முகவரி",
                        accessor: "address",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Amount / தொகை",
                        accessor: "amount",
                        sortable: false,
                      },
                      {
                        Header: "Status / நிலை",
                        accessor: "process_status",
                      },
                      {
                        Header: "Created At / பரிவர்த்தனை தேதி",
                        accessor: "create_date",
                      },
                    ]}
                    defaultPageSize={this.state.perPage}
                    // page={this.state.page}
                    pages={this.state.count}
                    onFetchData={(state, instance) =>
                      this.onFetchData(state, instance)
                    }
                    manual
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.transaction.list,
  user: state.user.login,
  listOperators: state.user.operators,
});

export default connect(mapStateToProps, {
  getTransactions,
})(ListTransaction);
