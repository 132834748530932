import { takeLatest, call, put } from "redux-saga/effects";

import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import { getSubscription } from "../schema/subscription";
import { getSubscriptionResponse } from "../actions";

export function* getAllSubscriptionEffectSaga({ payload, reslove, reject }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...getSubscription, variables: payload })
    );
    yield put(getSubscriptionResponse(data.data));
  } catch (e) {
    yield put(getSubscriptionResponse(e));
  }
}

export function* getAllSubscriptionWatcherSaga() {
  yield takeLatest(types.GET_ALL_SUBSCRIPTION, getAllSubscriptionEffectSaga);
}
