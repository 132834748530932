import {
  LIST_CHANNEL_RESPONSE,
  SINGLE_CHANNEL_RESPONSE,
  CREATE_CHANNEL_RESPONSE,
  UPDATE_CHANNEL_RESPONSE,
} from "../constants/ActionTypes";

const initialState = {
  listChannel: [],
  singleChannel: {}
};

const channelReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CHANNEL_RESPONSE:
      return { ...state, listChannel: action.payload };
    case SINGLE_CHANNEL_RESPONSE:
      return { ...state, singleChannel: action.payload };
    case CREATE_CHANNEL_RESPONSE:
      return { ...state, createChannel: action.payload };
    case UPDATE_CHANNEL_RESPONSE:
      return { ...state, updateChannel: action.payload };
    default:
      return state;
  }
};
export default channelReducer;
