import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { getPlanResponse, getSinglePlanResponse, createPlanResponse } from "../actions";
import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import { getPlan, updatePlanDetialsById, listPlan, planCreate } from "../schema/plan";

export function* listPlanEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...listPlan, variables: payload })
    );
    yield put(getPlanResponse(data.data));
  } catch (e) {
    yield put(getPlanResponse([]));
  }
}

export function* singlePlanEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...getPlan, variables: payload })
    );
    yield put(getSinglePlanResponse(data.data));
  } catch (e) {
    console.log(e);
    yield put(getSinglePlanResponse([]));
  }
}

export function* updatePlanDetialsEffectSaga({ payload, reslove, reject }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...updatePlanDetialsById, variables: payload })
    );
    if (data.errors) toast.success("Updat failed.");
    else toast.success("Successfully updated.");
  } catch (e) {
    yield reslove(e);
  }
}

export function* createPlanEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...planCreate, variables: payload })
    );
    if (data.data.planCreate) {
      yield put(createPlanResponse({ payload: { res: 'success', ...data } }));
      yield toast.success("Successfully added.");
    }
    else yield toast.error("User alredy exists.");
  } catch (e) {
    yield toast.error(e.toString());
  }
}

export function* listPlanWatcherSaga() {
  yield takeLatest(types.LIST_PLAN, listPlanEffectSaga);
}

export function* singlePlanWatcherSaga() {
  yield takeLatest(types.SINGLE_PLAN, singlePlanEffectSaga);
}

export function* updatePlanByIdSaga() {
  yield takeLatest(
    types.UPDATE_PLAN_DETAILS_BY_ID,
    updatePlanDetialsEffectSaga
  );
}

export function* createPlanWatcherSaga() {
  yield takeLatest(types.CREATE_PLAN, createPlanEffectSaga);
}
