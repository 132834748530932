import { all } from "redux-saga/effects";

import {
  listUserWatcherSaga,
  getUserByIdWatcherSaga,
  updateUserByIdWatcherSaga,
  addUserWatcherSaga,
  logoUploadWatcherSaga,
  loginUserWatcherSaga,
  countWatcherSaga,
  listOperatorWatcherSaga,
} from "./user";

import {
  listPlanWatcherSaga,
  singlePlanWatcherSaga,
  updatePlanByIdSaga,
  createPlanWatcherSaga,
} from "./plan";

import {
  createChannelWatcherSaga,
  updateChannelByIdWatcherSaga,
  singleChannelWatcherSaga,
  listChannelWatcherSaga,
} from "./channel";

import { getAllSubscriptionWatcherSaga } from "./subscription";

import { getNotificationWatcherSaga } from "./notification"

import {
  getAllTransactionWatcherSaga,
  createTransactionWatcherSaga,
  requestFundWatcherSaga,
  updateTransactionStatusWatcherSaga,
} from "./transaction";

export default function* rootSaga() {
  yield all([
    loginUserWatcherSaga(),
    listUserWatcherSaga(),
    listPlanWatcherSaga(),
    singlePlanWatcherSaga(),
    updatePlanByIdSaga(),
    getAllSubscriptionWatcherSaga(),
    getUserByIdWatcherSaga(),
    updateUserByIdWatcherSaga(),
    getAllTransactionWatcherSaga(),
    addUserWatcherSaga(),
    logoUploadWatcherSaga(),
    countWatcherSaga(),
    createPlanWatcherSaga(),
    createTransactionWatcherSaga(),
    getNotificationWatcherSaga(),
    createChannelWatcherSaga(),
    updateChannelByIdWatcherSaga(),
    singleChannelWatcherSaga(),
    listChannelWatcherSaga(),
    requestFundWatcherSaga(),
    listOperatorWatcherSaga(),
    updateTransactionStatusWatcherSaga(),
  ]);
}
