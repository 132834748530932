/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// reactstrap components
import { Card, CardBody, CardFooter, CardTitle, Row, Col, FormGroup, Input } from "reactstrap";

//import actions
import { getCount, requestFund, getUserById, updateUserById } from "../actions";
import SimpleReactValidator from "simple-react-validator";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thisMonthUser: "0",
      thisMounthPaidUser: "0",
      thisMounthUnpaidUser: "0",
      totaluser: "0",
      totalDeactiveUser: "0",
      showbankDetailsTextFields: false,
      bankAccount: "",
      bankIFSCCode: "",
      bankName: "",
      withdrawAmount: ""
    };
    this.BankFormValidator = new SimpleReactValidator();
    this.WithdrawValidator = new SimpleReactValidator();
  }
  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };

  componentDidMount = () => {
    let payload = {}
    if (this.props.user.login && this.props.user.login._id && this.props.user.login.role !== 'superadmin') {
      payload.user_id = this.props.user.login._id
    }
    this.props.getCount(payload);
    let userPayload = {
      id: this.props.user.login._id,
    };
    this.props.getUserById(userPayload);
  };

  componentDidUpdate(prevProps) {
    if (this.props.count !== prevProps.count) {
      this.setState(this.props.count.count);
    }
  }

  requestFundHandle = () => {
    if (this.WithdrawValidator.allValid()) {
      if (this.props.userById.account && !this.props.userById.isBankEnabled) {
        // process as razor pay transfer
        this.props.requestFund({
          amount: parseFloat(this.state.withdrawAmount), // parseFloat(this.props.userById.wallet_payment),
          currency: "INR",
          user_id: this.props.userById._id,
          isOffline: false
        })
        this.setState({ withdrawAmount: '' })
        setTimeout(() => {
          let userPayload = {
            id: this.props.user.login._id,
          };
          this.props.getUserById(userPayload);
        }, 1000)
      } else if (this.props.userById.isBankEnabled) {
        // process as offline bank transfer
        this.props.requestFund({
          amount: parseFloat(this.state.withdrawAmount), // parseFloat(this.props.userById.wallet_payment),
          currency: "INR",
          user_id: this.props.userById._id,
          isOffline: true
        })
        this.setState({ withdrawAmount: '' })
        setTimeout(() => {
          let userPayload = {
            id: this.props.user.login._id,
          };
          this.props.getUserById(userPayload);
        }, 1000)
      } else {
        // no bank details found, ask for bank details
        this.setState({ showbankDetailsTextFields: true })
      }
    } else {
      this.WithdrawValidator.showMessages()
      this.forceUpdate()
    }
  }

  selectedPaymentMethodStr = () => {
    if (this.props.userById.account && !this.props.userById.isBankEnabled) {
      // process as razor pay transfer
      return `Your account reference id ${this.props.userById.account}`;
    } else if (this.props.userById.isBankEnabled) {
      // process as offline bank transfer
      return `Your bank account number ${this.props.userById.bank_account_number}`;
    } else {
      return ``;
    }
  }
  UpdateBankDetails = (action = "save") => {
    if (action == "cancel") {
      this.BankFormValidator.hideMessages();
      this.setState({
        showbankDetailsTextFields: false,
        bankAccount: "",
        bankIFSCCode: "",
        bankName: "",
      })
      return;
    }
    if (this.BankFormValidator.allValid()) {

      this.setState({ showbankDetailsTextFields: false })
      let payload = {
        record: {
          bank_name: this.state.bankName,
          bank_account_number: this.state.bankAccount,
          bank_ifsc: this.state.bankIFSCCode,
          isBankEnabled: true,
          account :  this.state.bankAccount
        },
        filter: {
          _id: this.props.user.login._id,
        }
      };
      this.props.updateUserById(JSON.stringify(payload));

      setTimeout(() => {
        let userPayload = {
          id: this.props.user.login._id,
        };
        this.props.getUserById(userPayload);
      }, 1000)
    } else {
      this.BankFormValidator.showMessages();
      this.forceUpdate();
    }
  }

  changeState(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }

  render() {
    let { totaluser, thisMonthUser, thisMounthPaidUser, thisMounthUnpaidUser, totalDeactiveUser } = this.state
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-chat-33" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Users / மொத்த பயனர்கள்</p>
                        <CardTitle tag="h3">{totaluser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for over all / *ஒட்டுமொத்தமாக
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-chat-33" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Active Users / மொத்த செயலில் உள்ள பயனர்கள்</p>
                        <CardTitle tag="h3">{totaluser - totalDeactiveUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for over all / *ஒட்டுமொத்தமாக
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-chat-33" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Deactive Users / மொத்த செயலிழந்த பயனர்கள்</p>
                        <CardTitle tag="h3">{totalDeactiveUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for over all / *ஒட்டுமொத்தமாக
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-shape-star" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">This Month Users /இந்த மாத பயனர்கள்</p>
                        <CardTitle tag="h3">{thisMonthUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for this month / *இந்த மாதத்திற்கு
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Paid User / பணம் செலுத்திய பயனர்</p>
                        <CardTitle tag="h3">{thisMounthPaidUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for this month / *இந்த மாதத்திற்கு
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-molecule-40" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Unpaid / பணம் செலுத்தாத பயனர்கள்</p>
                        <CardTitle tag="h3">{thisMounthUnpaidUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    * for this month / *இந்த மாதத்திற்கு
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {this.props.userById && this.props.userById._id && this.props.userById.role == 'operator' ?
            <Row>
              <Col className="text-center" lg="12" sm="12">
                <Card className="card-contributions">
                  <CardBody>
                    <CardTitle tag="h1">INR {(this.props.userById.wallet_payment || 0).toFixed(2)}</CardTitle>
                    <h3 className="card-category">Total Wallet Balance</h3>
                    {this.props.userById.wallet_payment > 0 && <Row className="justify-content-center my-2">
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            placeholder="Enter Total Amount to Withdraw"
                            type="text"
                            value={this.state.withdrawAmount}
                            onChange={(e) => {
                              this.changeState(e, "withdrawAmount");
                            }}
                          />
                        </FormGroup>
                        {this.WithdrawValidator.message(
                          "withdraw Amount",
                          this.state.withdrawAmount,
                          `numeric|min:10,num|max:${this.props.userById.wallet_payment},num|required`,
                          { className: "text-danger" }
                        )}
                      </Col>
                    </Row>}
                    {
                      this.selectedPaymentMethodStr()
                      // this.props.userById.account ? <p className="card-description">
                      //   Your account reference id {this.props.userById.account}
                      // </p> : <p>
                      //   Contact admin, transfer many to  your bank account.
                      // </p>
                    }
                    {this.state.showbankDetailsTextFields &&
                      <div>
                        <Row>
                          <Col>
                            <p>
                              Please Add Bank Account Details to Get Payments
                            </p>
                          </Col>
                        </Row>
                        <Row className="justify-content-center my-2">
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                placeholder="Bank Name / வங்கி பெயர்"
                                type="text"
                                value={this.state.bankName}
                                onChange={(e) => {
                                  this.changeState(e, "bankName");
                                }}
                              />
                            </FormGroup>
                            {this.BankFormValidator.message(
                              "Bank Name",
                              this.state.bankName,
                              ("required"),
                              { className: "text-danger" }
                            )}
                          </Col>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                placeholder="Bank Account Number / வங்கி கணக்கு எண்"
                                type="text"
                                value={this.state.bankAccount}
                                onChange={(e) => {
                                  this.changeState(e, "bankAccount");
                                }}
                              />
                            </FormGroup>
                            {this.BankFormValidator.message(
                              "Bank Account Number",
                              this.state.bankAccount,
                              ("required"),
                              { className: "text-danger" }
                            )}
                          </Col>
                        </Row>
                        <Row className="justify-content-center my-2">
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                placeholder="Bank IFSC Code / வங்கி IFSC குறியீடு"
                                value={this.state.bankIFSCCode}
                                onChange={(e) => {
                                  this.changeState(e, "bankIFSCCode");
                                }}
                              />
                            </FormGroup>
                            {this.BankFormValidator.message(
                              "Bank ifsc Code",
                              this.state.bankIFSCCode,
                              ("required"),
                              { className: "text-danger" }
                            )}
                          </Col>
                          <Col sm="5"></Col>
                        </Row>
                        <Row>
                          <Col className="ml-auto mr-auto cursor-pointer">
                            <button className="stats btn btn-danger" onClick={() => this.UpdateBankDetails("cancel")}>
                              Cancel
                            </button>
                            <button className="stats btn" onClick={() => this.UpdateBankDetails()}>
                              Update Bank Details
                            </button>
                          </Col>
                        </Row>
                      </div>}
                  </CardBody>
                  <hr />
                  {this.props.userById.wallet_payment > 0 && !this.state.showbankDetailsTextFields && <CardFooter>
                    <Row>
                      <Col className="ml-auto mr-auto cursor-pointer">
                        <button className="stats btn"
                          // disabled={!this.props.userById.account}
                          onClick={() => this.requestFundHandle()}>
                          Transfer
                        </button>
                      </Col>
                    </Row>
                  </CardFooter>}
                </Card>
              </Col>
            </Row>
            : <> </>}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  count: state.user.count,
  user: state.user.login,
  userById: state.user.user && state.user.user.userById,
});

export default connect(mapStateToProps, {
  getCount,
  requestFund,
  getUserById,
  updateUserById
})(Dashboard);
