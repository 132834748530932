import { combineReducers } from "redux";

// Import custom components
import userReducer from "./user";
import planReducer from "./plan";
import subscriptionReducer from "./subscription";
import transactionReducer from "./transaction";
import channelReducer from "./channel";

const rootReducer = combineReducers({
  user: userReducer,
  plan: planReducer,
  subscription: subscriptionReducer,
  transaction: transactionReducer,
  channel: channelReducer,
});

export default rootReducer;
