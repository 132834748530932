import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup
} from "reactstrap";
import Select from "react-select";
//import actions
import { getUsers } from "../../actions";

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      count: 10,
      perPage: 10,
      loading: true,
      operators: [],
      operator_id: '',
    };
  }

  loadData = (data, pageInfo) => {
    this.setState({
      data: data.map((prop, key) => {
        return {
          phone: prop.phone,
          fullName: prop.fullname,
          email: prop.email,
          brand_name: prop.brand_name,
          address: prop.address,
          wallet_payment: prop.wallet_payment,
          actions: (
            <div className="actions-right">
              <Link to={`/admin/edit-operator/${prop._id}`}>
                <Button
                  color="warning"
                  size="sm"
                  className={classNames("btn-icon btn-link like", {
                    "btn-neutral": key < 5,
                  })}
                >
                  <i className="tim-icons icon-pencil" />
                </Button>
              </Link>
            </div>
          ),
        };
      }),
      count: pageInfo.pageCount,
      perPage: pageInfo.perPage,
      loading: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.listUser !== prevProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      this.loadData(
        this.props.listUser.userPagination.items,
        this.props.listUser.userPagination.pageInfo
      );
    }
    if (
      this.props.listOperators !== prevProps.listOperators &&
      this.props.listOperators.userPagination &&
      this.props.listOperators.userPagination.items
    ) {
      let items = [];
      this.props.listOperators.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      this.setState({ operators: items });
    }
  }

  onFetchData = (state, instance) => {
    // show the loading overlay
    this.setState({ loading: true });
    if (state.sorted.length) console.log(state.sorted[0].id.toUpperCase());
    let payload = {
      page: state.page + 1,
      perPage: state.pageSize,
      filter: {
        isUser: false,
        role: "employee"
      },
      sort: "_ID_DESC"
    };
    if (state.filtered.length) {
      state.filtered.forEach((data) => {
        payload.filter[data.id] = parseFloat(data.value) || data.value;
      });
    }
    if(this.props.user.login && this.props.user.login._id && this.props.user.login.role !== 'superadmin'){
      payload.filter.operator_id = this.props.user.login._id
    }
    if (state.filter) {
      payload.filter = payload.filter ? { ...payload.filter, ...state.filter } : state.filter
    }
    if (state.sorted.length)
      payload.sort =
        state.sorted[0].id.toUpperCase() +
        (!state.sorted[0].desc ? "_ASC" : "_DESC");
    this.props.getUsers(payload);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  operatorOnChangeHandler = (value) => {
    this.setState({ operator_id: value.value })
    this.onFetchData({
      ...this.state, filter: {
        operator_id: value.value
      },
      sorted: [],
      filtered: []
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">List Users</CardTitle>
                    </Col>
                    <Col sm="5">
                      <FormGroup>
                        <Select
                          className={`react-select info ${
                            this.props.user &&
                            this.props.user.login &&
                            this.props.user.login._id &&
                            this.props.user.login.role !== "superadmin" ? "d-none" : ''}`}
                          classNamePrefix="react-select"
                          name="operator_id"
                          value={this.state.operators.find(
                            (data) =>
                              data.value === this.state.operator_id
                          )}
                          onChange={this.operatorOnChangeHandler}
                          options={this.state.operators}
                          placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    loading={this.state.loading}
                    resizable={false}
                    columns={[
                      {
                        Header: "Full Name",
                        accessor: "fullName",
                      },
                      {
                        Header: "Company Name",
                        accessor: "brand_name",
                      },
                      {
                        Header: "Mobile",
                        accessor: "phone",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Address",
                        accessor: "address",
                      },
                      {
                        Header: "Wallet Blance",
                        accessor: "wallet_payment",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={this.state.perPage}
                    // page={this.state.page}
                    pages={this.state.count}
                    onFetchData={(state, instance) =>
                      this.onFetchData(state, instance)
                    }
                    manual
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listUser: state.user.listUser,
  user: state.user.login,
  listOperators: state.user.operators,
});

export default connect(mapStateToProps, {
  getUsers,
})(ListUser);
