/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

import { loginUsers } from "actions";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { setCookie } from "util/cookies";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      email: "",
      password: "",
      role: [],
      error: "",
      passWrd: true,
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  chage(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  update = async () => {
    if (this.validator.allValid()) {
      let payload = {
        email: this.state.email,
        password: this.state.password,
        fcm: "",
        deviceid: "",
        isUser: false,
      };
      this.props.loginUsers(JSON.stringify(payload));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentDidUpdate(previousProps) {
    if (this.props.user.login !== previousProps.user.login) {
      if (this.props.user.login.login) {
        if (this.props.user.login.login.role === "employee") {
          this.props.user.login.login.emp_id = this.props.user.login.login._id
          this.props.user.login.login._id = this.props.user.login.login.operator_id
        }
        setCookie("__user", JSON.stringify(this.props.user.login.login), 1);
        this.props.history.push("/admin/dashboard");
        this.setState({ error: "" });
      } else {
        this.setState({ error: "Wrong credential!" });
      }
    }
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader>
                    <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle tag="h1">Log in / உள்நுழை</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email (மின்னஞ்சல்) / Mobile (கைபேசி)"
                        onChange={(e) => {
                          this.chage(e, "email");
                        }}
                        value={this.state.email}
                        type="text"
                      />
                    </InputGroup>
                    {this.validator.message(
                      "email",
                      this.state.email,
                      "required|string",
                      { className: "text-danger" }
                    )}
                    <div className="pwdWrpr w-100">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password / கடவுச்சொல்"
                          type={this.state.passWrd ? 'password' : 'text'}
                          onChange={(e) => {
                            this.chage(e, "password");
                          }}
                          value={this.state.password}
                        />
                      </InputGroup>
                      {this.state.passWrd ?
                        <i className="pwdIcon fa fa-eye" aria-hidden="true" onClick={() => this.setState({ passWrd: false })}></i> :
                        <i className="pwdIcon fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ passWrd: true })}></i>
                      }
                    </div>
                    {this.validator.message(
                      "password",
                      this.state.password,
                      "required",
                      { className: "text-danger" }
                    )}
                    {this.state.error !== "" && (
                      <div className="text-center">
                        <p className="text-danger pt-2">{this.state.error}</p>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      href="#pablo"
                      onClick={this.update}
                      size="lg"
                    >
                      Login / உள்நுழை
                    </Button>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Create Account / கணக்கை துவங்கு
                        </a>
                      </h6>
                    </div> */}
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Need Help? / உதவி தேவையா?
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  loginUsers,
})(Login);
