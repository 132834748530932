import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  getUsersResponse,
  getUserByIdResponse,
  updateUserByIdResponse,
  logoUploadResponse,
  loginUsersResponse,
  getCountResponse,
  getOperatorsResponse,
  addUserResponse
} from "../actions";
import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import {
  listUser,
  userById,
  userUpdateOne,
  register,
  logoUpload,
  login,
  count,
} from "../schema/user";

export function* loginUserEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...login, variables: payload })
    );
    yield put(loginUsersResponse(data.data));
  } catch (e) {
    console.log(e);
    yield put(loginUsersResponse([]));
  }
}

export function* listUserEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...listUser, variables: payload })
    );
    yield put(getUsersResponse(data.data));
  } catch (e) {
    console.log(e);
    yield put(getUsersResponse([]));
  }
}

export function* listOperatorEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...listUser, variables: payload })
    );
    yield put(getOperatorsResponse(data.data));
  } catch (e) {
    console.log(e);
    yield put(getOperatorsResponse([]));
  }
}

export function* getUserByIdEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...userById, variables: payload })
    );
    yield put(getUserByIdResponse(data.data));
  } catch (e) {
    yield put(getUserByIdResponse({}));
  }
}

export function* updateUserByIdEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...userUpdateOne, variables: payload })
    );
    toast.success("Successfully updated.");
    yield put(updateUserByIdResponse(data.data));
  } catch (e) {
    yield put(updateUserByIdResponse({}));
  }
}

export function* addUserEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...register, variables: payload })
    );
    if (data.data.register) {
      yield put(addUserResponse({ data: { res: 'success', ...data } }));
      yield toast.success("Successfully added.")
    } else yield toast.error("User already exists.");
  } catch (e) {
    yield toast.error(e);
  }
}

export function* logoUploadEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...logoUpload, variables: payload })
    );
    yield put(logoUploadResponse(data.data));
  } catch (e) {
    yield put(logoUploadResponse(e));
  }
}

export function* countEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...count, variables: payload })
    );
    yield put(getCountResponse(data.data));
  } catch (e) {
    yield put(getCountResponse(e));
  }
}

export function* loginUserWatcherSaga() {
  yield takeLatest(types.LOGIN_USER, loginUserEffectSaga);
}

export function* listUserWatcherSaga() {
  yield takeLatest(types.LIST_USER, listUserEffectSaga);
}

export function* listOperatorWatcherSaga() {
  yield takeLatest(types.LIST_OPERATORS, listOperatorEffectSaga);
}

export function* getUserByIdWatcherSaga() {
  yield takeLatest(types.GET_USER_BY_ID, getUserByIdEffectSaga);
}

export function* updateUserByIdWatcherSaga() {
  yield takeLatest(types.UPDATE_USER_BY_ID, updateUserByIdEffectSaga);
}

export function* addUserWatcherSaga() {
  yield takeLatest(types.ADD_USER, addUserEffectSaga);
}

export function* logoUploadWatcherSaga() {
  yield takeLatest(types.LOGO_UPLOAD, logoUploadEffectSaga);
}

export function* countWatcherSaga() {
  yield takeLatest(types.GET_COUNT, countEffectSaga);
}
