import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
//import actions
import { getUsers, createTransactions } from "../../actions";

function statusFilter({ filter, onChange }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = [{ label: "Active", value: "1" }, { label: "In Active", value: "0" }]

  // Render a multi-select box
  return (
    <select
      value={filter ? filter.value : "All"}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option.value.toString()}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      count: 10,
      perPage: 10,
      operators: [],
      operator_id: '',
    };
  }

  loadData = (data, pageInfo) => {
    this.setState({
      data: data.map((prop, key) => {
        let payload = {
          amount: prop.Subscription ? prop.Subscription.next_pay_amount : 0,
          user_id: prop._id,
          ref_id: prop.Subscription && prop.Subscription._id,
          payment_id: prop.Subscription && prop.Subscription.Plan && prop.Subscription.Plan.operator_id,
          emp_id: this.props.user.login && this.props.user.login.emp_id,
          signature: "manual"
        }
        return {
          phone: prop.phone,
          fullName: prop.fullname,
          email: prop.email,
          device_id: prop.device_id,
          address: prop.address,
          status: prop.status === "1" ? "Active" : "In Active",
          plan: prop.Subscription && prop.Subscription.Plan && prop.Subscription.Plan.name,
          next_pay_amount: prop.Subscription ? prop.Subscription.next_pay_amount : 0,
          pending_pay_amount: prop.Subscription && prop.Subscription.pending_pay_amount,
          actions: (
            <div className="actions-right">
              <Link to={`/admin/edit-user/${prop._id}`}>
                <Button
                  color="warning"
                  size="sm"
                  className={classNames("btn-icon btn-link like", {
                    "btn-neutral": key < 5,
                  })}
                >
                  <i className="tim-icons icon-pencil" />
                </Button>
              </Link>
              {prop.Subscription && prop.Subscription.next_pay_amount ?
                <Button
                  color="warning"
                  size="sm"
                  className={classNames("btn-icon btn-link like", {
                    "btn-neutral": key < 5,
                  })}
                  onClick={() => this.props.createTransactions(payload)}
                >
                  <i className="tim-icons icon-money-coins" />
                </Button> : ''}
            </div>
          ),
        };
      }),
      count: pageInfo.pageCount,
      perPage: pageInfo.perPage,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.listUser !== prevProps.listUser &&
      this.props.listUser.userPagination &&
      this.props.listUser.userPagination.items
    ) {
      this.loadData(
        this.props.listUser.userPagination.items,
        this.props.listUser.userPagination.pageInfo
      );
    }
    if (this.props.transaction !== prevProps.transaction) {
      window.location.reload()
    }

    if (
      this.props.listOperators !== prevProps.listOperators &&
      this.props.listOperators.userPagination &&
      this.props.listOperators.userPagination.items
    ) {
      let items = [];
      this.props.listOperators.userPagination.items.map((item) => {
        items.push({
          value: item._id,
          label: item.fullname + " | " + item.phone,
        });
        return items;
      });
      this.setState({ operators: items });
    }
  }

  onFetchData = (state, instance) => {
    // show the loading overlay
    this.setState({ loading: true });
    if (state.sorted.length) console.log(state.sorted[0].id.toUpperCase());
    let payload = {
      page: state.page + 1,
      perPage: state.pageSize,
      filter: {
        isUser: true,
      },
      sort: "_ID_DESC",
    };
    if (state.filtered.length) {
      state.filtered.forEach((data) => {
        data.value = ["status", "phone", "box"].includes(data.id) ? data.value.toString() : parseFloat(data.value) || data.value
        payload.filter[data.id] = data.value;
      });
    }
    if (this.props.user.login && this.props.user.login._id && this.props.user.login.role !== 'superadmin') {
      payload.filter.operator_id = this.props.user.login._id;
    }
    if (state.filter) {
      payload.filter = payload.filter ? { ...payload.filter, ...state.filter } : state.filter
    }
    if (state.sorted.length)
      payload.sort =
        state.sorted[0].id.toUpperCase() +
        (!state.sorted[0].desc ? "_ASC" : "_DESC");
    this.props.getUsers(payload);
  };

  operatorOnChangeHandler = (value) => {
    this.setState({ operator_id: value.value })
    this.onFetchData({
      ...this.state, filter: {
        operator_id: value.value
      },
      sorted: [],
      filtered: []
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">List Users / பயனர்களின் பட்டியல்</CardTitle>
                    </Col>
                    <Col sm="5">
                      <FormGroup>
                        <Select
                          className={`react-select info ${
                            this.props.user &&
                            this.props.user.login &&
                            this.props.user.login._id &&
                            this.props.user.login.role !== "superadmin" ? "d-none" : ''}`}
                          classNamePrefix="react-select"
                          name="operator_id"
                          value={this.state.operators.find(
                            (data) =>
                              data.value === this.state.operator_id
                          )}
                          onChange={this.operatorOnChangeHandler}
                          options={this.state.operators}
                          placeholder="Select Operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Full Name / முழு பெயர்",
                        accessor: "fullName",
                      },
                      {
                        Header: "Mobile / கைபேசி",
                        accessor: "phone",
                      },
                      {
                        Header: "Email / மின்னஞ்சல்",
                        accessor: "email",
                      },
                      {
                        Header: "Address / முகவரி",
                        accessor: "address",
                      },
                      {
                        Header: "Box ID / பெட்டி ஐடி",
                        accessor: "device_id",
                      },
                      {
                        Header: "Plan / சந்தா",
                        accessor: "plan",
                      },
                      {
                        Header: "Amount / தொகை",
                        accessor: "next_pay_amount",
                        sortable: false
                      },
                      {
                        Header: "Pending Amount / நிலுவை தொகை",
                        accessor: "pending_pay_amount",
                        sortable: false
                      },
                      {
                        Header: "Status / நிலை",
                        accessor: "status",
                        sortable: false,
                        Filter: statusFilter,
                        filter: 'includes'
                      },
                      {
                        Header: "Actions / நடவடிக்கை",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={this.state.perPage}
                    // page={this.state.page}
                    pages={this.state.count}
                    onFetchData={(state, instance) =>
                      this.onFetchData(state, instance)
                    }
                    manual
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listUser: state.user.listUser,
  listOperators: state.user.operators,
  user: state.user.login,
  transaction: state.transaction.update
});

export default connect(mapStateToProps, {
  getUsers,
  createTransactions,
})(ListUser);
