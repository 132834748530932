import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import * as types from "../constants/ActionTypes";

import { axiosPost } from "../util/axios";
import { getTransaction, transactionUpdateOne, transactionRequestMoney, transactionUpdateRequestMoney } from "../schema/transaction";
import { getTransactionResponse, createTransactionResponse, requestFundResponse, updateTransactionStatusResponse } from "../actions";

export function* getAllTransactionEffectSaga({ payload, reslove, reject }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...getTransaction, variables: payload })
    );
    yield put(getTransactionResponse(data.data));
  } catch (e) {
    yield put(getTransactionResponse(e));
  }
}

export function* createTransactionEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...transactionUpdateOne, variables: payload })
    );
    yield put(createTransactionResponse(data.data));
    // window.location.reload()
  } catch (e) {
    yield put(createTransactionResponse(e));
  }
}

export function* requestFundEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...transactionRequestMoney, variables: payload })
    );
    toast.success("withdrawal request started successfully");
    yield put(requestFundResponse(data.data));
    // window.location.reload()
  } catch (e) {
    yield put(requestFundResponse(e));
  }
}

export function* updateTransactionStatusEffectSaga({ payload }) {
  try {
    let { data } = yield call(() =>
      axiosPost({ ...transactionUpdateRequestMoney, variables: payload })
    );
    yield put(updateTransactionStatusResponse(data.data));
    // window.location.reload()
  } catch (e) {
    yield put(updateTransactionStatusResponse(e));
  }
}

export function* getAllTransactionWatcherSaga() {
  yield takeLatest(types.GET_ALL_TRANSACTION, getAllTransactionEffectSaga);
}

export function* createTransactionWatcherSaga() {
  yield takeLatest(types.CREATE_TRANSACTION, createTransactionEffectSaga);
}

export function* requestFundWatcherSaga() {
  yield takeLatest(types.REQUEST_FUND, requestFundEffectSaga);
}

export function* updateTransactionStatusWatcherSaga() {
  yield takeLatest(types.UPDATE_TRANSACTION_STATUS_BY_ID, updateTransactionStatusEffectSaga);
}
