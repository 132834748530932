import {
  LIST_PLAN_RESPONSE,
  SINGLE_PLAN_RESPONSE,
  UPDATE_PLAN_RESPONSE,
  CREATE_PLAN_RESPONSE
} from "../constants/ActionTypes";

const initialState = {
  listPlan: []
};

const vechileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PLAN_RESPONSE:
      return { ...state, listPlan: action.data };
    case SINGLE_PLAN_RESPONSE:
      return { ...state, singlePlan: action.data };
    case CREATE_PLAN_RESPONSE:
      return { ...state, createPlan: action.data };
    case UPDATE_PLAN_RESPONSE:
      return { ...state, updatePlan: action.data };
    default:
      return state;
  }
};
export default vechileReducer;
