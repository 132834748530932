import * as types from "constants/ActionTypes";


export const getCount = (payload = {}) => ({
  type: types.GET_COUNT,
  payload
});

export const getCountResponse = (data = {}) => ({
  type: types.COUNT_RESPONSE,
  data
});

//it seems that I should probably use this as the basis for "User"
export const getUsers = (payload = {}) => ({
  type: types.LIST_USER,
  payload
});

export const getUsersResponse = (data = {}) => ({
  type: types.LIST_USER_RESPONSE,
  data
});

export const getOperators = (payload = {}) => ({
  type: types.LIST_OPERATORS,
  payload
});

export const getOperatorsResponse = (data = {}) => ({
  type: types.LIST_OPERATORS_RESPONSE,
  data
});

export const loginUsers = (payload = {}) => ({
  type: types.LOGIN_USER,
  payload
});

export const loginUsersResponse = (data = {}) => ({
  type: types.LOGIN_USER_RESPONSE,
  data
});

export const createPlan = (payload = {}) => ({
  type: types.CREATE_PLAN,
  payload
});

export const createPlanResponse = (data = {}) => ({
  type: types.CREATE_PLAN_RESPONSE,
  data
});

export const getPlan = (payload = {}) => ({
  type: types.LIST_PLAN,
  payload
});

export const getPlanResponse = (data = {}) => ({
  type: types.LIST_PLAN_RESPONSE,
  data
});

export const getSinglePlan= (payload = {}) => ({
  type: types.SINGLE_PLAN,
  payload
});

export const getSinglePlanResponse = (data = {}) => ({
  type: types.SINGLE_PLAN_RESPONSE,
  data
});

export const updatePlanDetailsById = (payload={},reslove,reject) =>({
    type: types.UPDATE_PLAN_DETAILS_BY_ID,
    payload,
    reslove,
    reject
})

export const updatePlanDetailsByIdResponse = (data={}) =>({
    type: types.UPDATE_PLAN_RESPONSE,
    data
})

export const getUserById = (payload={}) => ({
  type: types.GET_USER_BY_ID,
  payload
})

export const getUserByIdResponse = (data={}) =>({
  type: types.GET_USER_BY_ID_RESPONSE,
  data
})

export const updateUserById = (payload={}) =>({
  type: types.UPDATE_USER_BY_ID,
  payload
})

export const updateUserByIdResponse = (data={})=>({
  type: types.UPDATE_USER_BY_ID_RESPONSE,
  data
})

export const addUser = (payload={}) =>({
  type: types.ADD_USER,
  payload
})

export const addUserResponse = (data={})=>({
  type: types.ADD_USER_RESPONSE,
  data
})


export const getSubscription = (payload = {}) => ({
  type: types.GET_ALL_SUBSCRIPTION,
  payload
});

export const getSubscriptionResponse = (data = {}) => ({
  type: types.GET_ALL_SUBSCRIPTION_RESPONSE,
  data
});

export const getTransactions = (payload = {}) => {
  return {
    type: types.GET_ALL_TRANSACTION,
    payload
  }
}

export const getTransactionResponse = (data = {}) => ({
  type: types.GET_ALL_TRANSACTION_RESPONSE,
  data,
})


export const logoUpload = (payload = {}) => {
  return {
    type: types.LOGO_UPLOAD,
    payload
  }
}

export const logoUploadResponse = (data = {}) => ({
  type: types.LOGO_UPLOAD_RESPONSE,
  data,
})

export const createTransactions = (payload = {}) => {
  return {
    type: types.CREATE_TRANSACTION,
    payload
  }
}

export const createTransactionResponse = (data = {}) => ({
  type: types.CREATE_TRANSACTION_RESPONSE,
  data,
})

export const getNotification = (payload = {}) => {
  return {
    type: types.GET_NOTIFICATION_LIST,
    payload
  }
}

export const getNotificationResponse = (data = {}) => ({
  type: types.GET_NOTIFICATION_RESPONSE,
  data,
})

export const createChannel = (payload = {}) => ({
  type: types.CREATE_CHANNEL,
  payload
});

export const createChannelResponse = (payload = {}) => ({
  type: types.CREATE_CHANNEL_RESPONSE,
  payload
});

export const getChannel = (payload = {}) => ({
  type: types.LIST_CHANNEL,
  payload
});

export const getChannelResponse = (payload = {}) => ({
  type: types.LIST_CHANNEL_RESPONSE,
  payload
});

export const getSingleChannel= (payload = {}) => ({
  type: types.SINGLE_CHANNEL,
  payload
});

export const getSingleChannelResponse = (payload = {}) => ({
  type: types.SINGLE_CHANNEL_RESPONSE,
  payload
});

export const updateChannelDetailsById = (payload={},reslove,reject) =>({
    type: types.UPDATE_CHANNEL_DETAILS_BY_ID,
    payload,
    reslove,
    reject
})

export const updateChannelDetailsByIdResponse = (payload={}) =>({
    type: types.UPDATE_CHANNEL_RESPONSE,
    payload
})

export const requestFund = (payload={}) =>({
  type: types.REQUEST_FUND,
  payload
})

export const requestFundResponse = (payload={}) =>({
  type: types.REQUEST_FUND_RESPONSE,
  payload
})

export const updateTransactionStatus = (payload={})=>({
  type: types.UPDATE_TRANSACTION_STATUS_BY_ID,
  payload
})

export const updateTransactionStatusResponse = (payload={})=>({
  type: types.UPDATE_TRANSACTION_STATUS_BY_ID_RESPONSE,
  payload
})
