const axios = require('axios');
const { getCookie } = require('./cookies')

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
if(getCookie("__user") && getCookie("__user") !=="")
axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getCookie("__user")).token}`;
// axios.defaults.withCredentials = true


// let user = JSON.parse(localStorage.getItem('state')) && JSON.parse(localStorage.getItem('state')).user.login
// if(user) axios.defaults.headers.common['Token'] = user.token;

// Make a request for a common post records
// exports.axiosPost = (payload) => axios.post('', payload)
exports.axiosPost = (payload) => {
    if (!axios.defaults.headers.common['Authorization'] && getCookie("__user") && getCookie("__user") !== "")
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getCookie("__user")).token}`;
    return axios.post('', payload)
}